import { filter, orderBy, flatten } from 'lodash';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { styled, useTheme } from '@mui/material/styles';
import DatePicker from 'react-datepicker'


// material
import {
  Card,
  Table,
  Stack,
  // Avatar,
  // Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { OrderListHead, OrderListToolbar } from '../components/_dashboard/order';
//
import { getAllOrders } from '../services/tradesServices'

import { fCDateTime } from '../utils/formatTime';
import { fCurrency } from '../utils/formatNumber';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'symbol', label: '交易对' },
  { id: 'side', label: "类型" },
  // { id: 'realizedProfit', label: '实现盈亏' },
  { id: 'quantity', label: '成交数量' },
  { id: 'averagePrice', label: '均价' },
  // { id: 'time', label: '创建时间' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderedBy) {
  if (b[orderedBy] < a[orderedBy]) {
    return -1;
  }
  if (b[orderedBy] > a[orderedBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderedBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderedBy)
    : (a, b) => -descendingComparator(a, b, orderedBy);
}

function applySortFilter(array, comparator, query, unrealizePNL = false) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (!unrealizePNL) {
    stabilizedThis = filter(stabilizedThis, (_order) => parseFloat(_order[0].realizedPnl).toFixed(5) !== '0.00000')
  }
  if (query) {
    return filter(
      stabilizedThis,
      (_order) => _order[0].symbol.toLowerCase().indexOf(query.toLowerCase()) !== -1
    ).map((el) => el[0]);
  }
  return stabilizedThis.map((el) => el[0]);
}
const AllOrder = (props) => {
  // const trades = props.orders;
  const { accountId } = props
  const theme = useTheme();
  const red = theme.palette.error.main
  const green = theme.palette.success.main

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderedBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trades, setTrades] = useState([])
  const [orderSide, setOrderSide] = useState('BUY')
  const setDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const orderedTrades = orderBy(flatten(Object.values(trades)), ['time'], ['desc']);

  useEffect(() => {
    endDate?.setHours(23, 59, 59)
    startDate?.setHours(0, 0, 0)
    const startTime = startDate?.getTime()
    const endTime = endDate?.getTime()
    if (accountId && startDate && endDate) {
      setLoading(true)
      getAllOrders({ accountId, startTime, endTime }).then((res) => {
        setLoading(false)
        setTrades(res[orderSide] || [])
      })
    }
  }, [accountId, startDate, endDate, orderSide])

  const handleRequestSort = (event, property) => {
    const isAsc = orderedBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderedTrades.map((n) => n.symbol);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderedTrades.length) : 0;

  const filteredOrders = applySortFilter(
    orderedTrades,
    getComparator(order, orderedBy),
    filterName
  );

  const isOrderNotFound = filteredOrders.length === 0;

  return (
    <Container sx={{ mt: 5 }} style={{ maxWidth: '1600px' }}>

      <Card style={{ maxWidth: '1600px', minHeight: '400px' }}>
        <Stack justifyContent="space-between"
          alignItems="center" sx={{ ml: { lg: 2 }, mt: { xs: 3 }, flexDirection: { lg: "row", xs: 'column' } }}>
          <div >
            <DatePicker
              selectsRange
              startDate={startDate}
              dateFormat="yyyy/MM/dd"
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              className="data-picker"
            />
          </div>
          <OrderListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        </Stack>
        <ToggleButtonGroup
          color="primary"
          value={orderSide}
          exclusive
          onChange={(event, newType) => {
            setOrderSide(newType);
          }}
          aria-label="Platform"
          sx={{ ml: 2 }}
        >
          <ToggleButton value="BUY">做多</ToggleButton>
          <ToggleButton value="SELL">做空</ToggleButton>
        </ToggleButtonGroup>
        {loading ? <CircularProgress /> :
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderListHead
                  order={order}
                  orderBy={orderedBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderedTrades.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { symbol, avgPrice, time, side, averagePrice, executedQty, quantity } = row;
                      const isItemSelected = selected.indexOf(symbol) !== -1;

                      return (
                        <TableRow
                          hover
                          key={symbol}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="center">
                            <Typography variant="subtitle2" noWrap>
                              {symbol}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ color: `${side === 'SELL' ? green : red}` }}>{`${side === 'SELL' ? '做空' : '做多'}`}</TableCell>
                          {/* <TableCell align="center">{fCurrency(realizedProfit)}</TableCell> */}
                          <TableCell align="center">{fCurrency(quantity)}</TableCell>
                          <TableCell align="center">{fCurrency(averagePrice)}</TableCell>
                          {/* <TableCell align="center">{fCDateTime(time)}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isOrderNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          暂无数据
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        }

        <TablePagination
          rowsPerPageOptions={[25, 100, 1000]}
          component="div"
          count={filteredOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

export default AllOrder;
