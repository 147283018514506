
import axios from 'axios';
import { getUrl } from './utils';

const UN_LOGIN_CODE = 2001
export const getSimPerformance = async () => {

    const response = await axios.request({
        url: getUrl('simPerformance'),
        method: 'get',
    });
    if (response?.data?.code === UN_LOGIN_CODE) {
        window.location.href = '/loginPage'
    }
    return response.data;
};

export const getSimStatistics = async () => {

    const response = await axios.request({
        url: getUrl('simStatistics'),
        method: 'get',
    });
    if (response?.data?.code === UN_LOGIN_CODE) {
        window.location.href = '/loginPage'
    }
    return response.data;
};



