import React, { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import { Box, Typography, Stack, Grid, Card } from '@mui/material';
import { fCurrency, fPercent } from '../utils/formatNumber';

const swapSocketUrl = 'wss://fstream.binance.com/stream';
const spotSocketUrl = 'wss://stream.binance.com:9443/stream';
const coinSocketUrl = 'wss://dstream.binance.com/stream'

const RealTimePrice = ({ symbol, productType, callback, price }) => {

    let socketUrl = ''
    if (productType === 'SWAP') {
        socketUrl = swapSocketUrl
    } else if (productType === 'COIN') {
        socketUrl = coinSocketUrl
    } else {
        socketUrl = spotSocketUrl
    }
    const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);


    useEffect(() => {
        if (callback) {
            callback(lastJsonMessage?.data?.c)
        }
    }, [lastJsonMessage, callback]);

    const subscribeWebsocket = useCallback(
        () => {
            sendJsonMessage({
                method: 'SUBSCRIBE',
                params: [`${symbol?.toLowerCase()}@ticker`],
                id: 1
            })
        },
        [sendJsonMessage, symbol]
    );

    const unsubscribeWebsocket = useCallback(
        () =>
            sendJsonMessage({
                method: 'UNSUBSCRIBE',
                params: [`${symbol?.toLowerCase()}@ticker`],
                id: 1
            }),
        [sendJsonMessage, symbol]
    );

    useEffect(() => {
        callback(0.0)
        subscribeWebsocket();

        return () => {
            unsubscribeWebsocket();
        };
    }, [symbol, productType, unsubscribeWebsocket, subscribeWebsocket, callback]);

    return (
        <Stack
        >
            <Typography sx={{ color: 'text.info' }}>
                当前币价{fCurrency(price)}
            </Typography>

        </Stack>
    );
};

export default RealTimePrice;