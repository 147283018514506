import { isEmpty, flatten } from 'lodash';
import { format } from 'date-fns';
import axios from 'axios';

import { BINANCE_HEADERS, getSignature, getUrl } from './utils';
import { generateLastNdates, generateLastNweeks } from '../utils/formatTime';

const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;
const ONE_WEEK_TIMESTAMP = 7 * 60 * 60 * 24 * 1000;
const CHUNK_NUMBER = 1;

const UN_LOGIN_CODE = 2001

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数。
  if (response?.data?.code === UN_LOGIN_CODE) {
    window.location.href = '/loginPage'
  } else {
    return response;
  }
}, (error) =>
  // 超出 2xx 范围的状态码都会触发该函数。
  console.error(error)
);

const generateNChunksRequest = (date, accountId) => {
  if (isEmpty(date)) return [];


  const dateRequested = new Date(date).getTime();
  const requests = [];

  for (let i = 1; i <= CHUNK_NUMBER; i += 1) {
    // const chunkTime = Math.round(ONE_DAY_TIMESTAMP / CHUNK_NUMBER);
    const chunkTime = Math.round(ONE_WEEK_TIMESTAMP / CHUNK_NUMBER);

    const startTime = dateRequested + (i - 1) * chunkTime;
    const endTime = startTime + chunkTime;

    if (startTime <= new Date().getTime()) {
      // Add the request only if the startTime is in the past

      const params = {
        startTimestamp: startTime,
        endTimestamp: endTime,
        accountId,
        asset: 'USDT'
      };

      requests.push(
        axios.request({
          url: getUrl('ownTrades'),
          method: 'get',
          params,
          headers: BINANCE_HEADERS
        })
      );
    }
  }

  return requests;
};

export const getUserTradesByDate = async (date, accountId) => {
  if (isEmpty(date)) return [];

  const response = await axios
    .all(generateNChunksRequest(date, accountId))
    .then(axios.spread((...responses) => flatten(responses.map((res) => res.data.data))))
    .catch((errors) => {
      console.error('Something went wrong when fetching getUserTradesByDate, error: ', errors);
    });

  return response;
};

export const getUserTradesByWeek = async (date, accountId) => {
  if (isEmpty(date)) return [];

  const response = await axios
    .all(generateNChunksRequest(date, accountId))
    .then(axios.spread((...responses) => flatten(responses.map((res) => res.data.data))))
    .catch((errors) => {
      console.error('Something went wrong when fetching getUserTradesByDate, error: ', errors);
    });

  return response;
};

export const getUserTradesOfTheDay = ({ accountId }) => getUserTradesByDate(format(new Date(), 'MM/dd/yyyy'), accountId);

export const getTradesOfTheWeek = async ({ accountId }) => {
  const lastWeekDates = generateLastNdates(7, 'MM/dd/yyyy');
  const trades = {};

  await axios
    .all(lastWeekDates.map((day) => getUserTradesByDate(day, accountId)))
    .then(
      axios.spread((...responses) => {
        lastWeekDates.forEach((day, index) => {
          trades[day] = responses[index];
        });
      })
    )
    .catch((errors) => {
      console.error('Something went wrong when fetching all the weekly trades, error: ', errors);
    });

  return trades;
};

export const getTradesOfTheMonth = async ({ accountId }) => {
  // const lastMonthWeeks = generateLastNdates(50, 'MM/dd/yyyy');
  const lastMonthWeeks = generateLastNweeks(8, 'MM/dd/yyyy')
  const trades = {};

  await axios
    .all(lastMonthWeeks.map((day) => getUserTradesByDate(day, accountId)))
    .then(
      axios.spread((...responses) => {
        lastMonthWeeks.forEach((day, index) => {
          trades[day] = responses[index];
        });
      })
    )
    .catch((errors) => {
      console.error('Something went wrong when fetching all the weekly trades, error: ', errors);
    });

  return trades;
};


export const getOrderType = async () => {

  const response = await axios.request({
    url: getUrl('orderType'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getSpotSymbols = async () => {

  const response = await axios.request({
    url: getUrl('spotSymbols'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getSwapSymbols = async () => {

  const response = await axios.request({
    url: getUrl('swapSymbols'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getCoinSymbols = async () => {

  const response = await axios.request({
    url: getUrl('coinSymbols'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getTransferEnum = async () => {

  const response = await axios.request({
    url: getUrl('transferEnum'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getOrderSide = async () => {

  const response = await axios.request({
    url: getUrl('getOrderSide'),
    method: 'get',
  });

  return response?.data?.data;
};

export const getOpenOrders = async ({ accountId, productType }) => {
  if (!accountId || !productType) {
    return ({
      data: []
    })
  }
  const param = {
    accountId,
    productType
  }
  const response = await axios.request({
    url: `${getUrl('openOrders')}`,
    method: 'post',
    data: param
  });

  return response?.data
}

export const getAllOrders = async ({ accountId, startTime = null, endTime = null }) => {
  if (!accountId) {
    return ({
      data: []
    })
  }
  const response = await axios.request({
    url: `${getUrl('allOrders')}`,
    method: 'post',
    data: { accountId, startTime, endTime }
  });

  return response?.data?.data;
};

export const makeOrder = async ({
  accountId,
  productType,
  symbol,
  side,
  timeInForce = 'GTC',
  type,
  price,
  quantity,
  quoteOrderQty,
  stopPrice,
  workingType,
  positionSide,
  reduceOnly,
  flatFlag
}) => {
  if (!accountId) {
    return { msg: "请选择交易账户" }
  }
  const param = {
    accountId,
    productType,
    symbol,
    side,
    timeInForce,
    type,
    price,
    quantity,
    flatFlag
  }
  if (positionSide) {
    param.positionSide = positionSide
  }

  if (type === "MARKET") {
    delete param.price
    delete param.timeInForce
  }

  if (type === 'STOP_MARKET' || type === 'TAKE_PROFIT_MARKET') {
    param.workingType = workingType
    param.stopPrice = stopPrice
    param.reduceOnly = reduceOnly
    delete param.price
  }

  if (quoteOrderQty) {
    param.quoteOrderQty = quoteOrderQty
    delete param.quantity
  }

  const response = await axios.request({
    url: `${getUrl('makeOrder')}`,
    method: 'post',
    data: param
  });

  return response?.data
}

export const cancelOrder = async ({ accountId, productType, list }) => {

  if (!accountId) {
    return { msg: "请选择交易账户" }
  }

  const param = list.map((item) => ({ accountId, productType, ...item }))

  try {
    const response = await axios.request({
      url: `${getUrl('cancelOrder')}`,
      method: 'post',
      data: param
    });
    return response?.data
  } catch (error) {
    return new Error("网络请求错误")
  }

}

export const getPositionRisk = async ({ accountId, symbol, productType }) => {
  if (!symbol || !accountId) {
    return
  }
  const params = {
    accountId,
    symbol
  }
  const url = productType === 'COIN' ? getUrl('coinPositionRisk') : getUrl('positionRisk')
  let response = {}
  if (productType === 'COIN') {
    params.productType = productType
    response = await axios.request({
      url,
      method: 'post',
      data: params
    });
    console.log('@@@response', response)
    response.data = { data: [{ leverage: response?.data?.leverage }] }
  } else {
    response = await axios.request({
      url,
      method: 'get',
      params
    });
  }
  return response?.data;
};


export const setLeverage = async ({ accountId, symbol, leverage, productType }) => {
  if (!accountId) {
    return { msg: "请选择交易账户" }
  }
  const param = {
    accountId,
    symbol,
    leverage,
    productType
  }

  try {
    const response = await axios.request({
      url: `${getUrl('setLeverage')}`,
      method: 'post',
      data: param
    });
    return response?.data
  } catch (error) {
    return new Error("网络请求错误")
  }

}

export const getDualSidePosition = async ({ accountId, account, productType }) => {
  if (!accountId) {
    return ''
  }
  const params = {
    accountId,
  }
  const url = productType === 'COIN' ? getUrl('coinDualSidePosition') : getUrl('dualSidePosition')
  const response = await axios.request({
    url,
    method: 'get',
    params
  });

  return response?.data;
};

export const setDualSidePosition = async ({ accountId, dualSidePosition, productType }) => {
  if (!accountId) {
    return { msg: "请选择交易账户" }
  }

  const param = {
    accountId,
    dualSidePosition
  }
  const url = productType === 'COIN' ? getUrl('coinDualSidePosition') : getUrl('dualSidePosition')
  const response = await axios.request({
    url,
    method: 'post',
    params: param
  });

  return response?.data
}

export const calculateOrder = async ({
  accountId,
  productType,
  symbol,
  side,
  timeInForce = 'GTC',
  type,
  price,
  quantity,
  quoteOrderQty,
  stopPrice,
  workingType,
  positionSide,
  reduceOnly,
  flatFlag
}) => {
  if (!accountId) {
    return { msg: "请选择交易账户" }
  }
  const param = {
    accountId,
    productType,
    symbol,
    side,
    timeInForce,
    type,
    price,
    quantity,
    flatFlag
  }
  if (positionSide) {
    param.positionSide = positionSide
  }

  if (type === "MARKET") {
    delete param.price
    delete param.timeInForce
  }

  if (type === 'STOP_MARKET' || type === 'TAKE_PROFIT_MARKET') {
    param.workingType = workingType
    param.stopPrice = stopPrice
    param.reduceOnly = reduceOnly
    delete param.price
  }

  if (quoteOrderQty) {
    param.quoteOrderQty = quoteOrderQty
    delete param.quantity
  }

  const response = await axios.request({
    url: `${getUrl('calculateOrder')}`,
    method: 'post',
    data: param
  });

  return response?.data
}