import { merge, sum, max, flatten, min } from 'lodash';
import { format } from 'date-fns';
import ReactApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';

// material
import { Card, CardHeader, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { BaseOptionChart } from '../../charts';
import { fPercent } from '../../../utils/formatNumber';
import { generateLastNdates } from '../../../utils/formatTime';
import { incomesAtom, ownAccountAtom } from '../../../recoil/atoms';

// ----------------------------------------------------------------------

const lastWeekDates = generateLastNdates(7, 'MM/dd/yyyy').reverse();
const lastMonthDates = generateLastNdates(7, 'MM/dd/yyyy').reverse();
const lastNDates = (n) => generateLastNdates(n, 'MM/dd/yyyy').reverse();

const getIncomeForDate = (incomes, date) => {
  const incomeList = incomes[format(new Date(date), 'MM/dd/yyyy')];
  return sum(incomeList?.map((inc) => JSON.parse(inc?.income)));
};

const getIncomeOfWeek = (incomes, isRounded = false) => {
  const incomeOfWeek = lastWeekDates.map((date) => getIncomeForDate(incomes, date));
  return isRounded
    ? incomeOfWeek.map((inc) => Math.round(inc))
    : incomeOfWeek.map((inc) => parseFloat(inc.toFixed(3)));
};

const getIncomeOfMonth = (incomes, isRounded = false) => {
  const incomeOfMonth = lastMonthDates.map((date) => getIncomeForDate(incomes, date));
  console.log('income', incomeOfMonth);
  return isRounded
    ? incomeOfMonth.map((inc) => Math.round(inc))
    : incomeOfMonth.map((inc) => parseFloat(inc.toFixed(3)));
};

const getBalanceLastWeek = (incomes, balance) => {
  let sumIncome = 0;
  const balances = [];

  const incomeOfWeek = getIncomeOfWeek(incomes).reverse();
  incomeOfWeek.forEach((inc, i) => {
    sumIncome += inc;
    balances.push(Math.round(balance - sumIncome + incomeOfWeek[i]));
  });

  return balances.reverse();
};

const getIncomeOfNDay = (incomes, n = 0, isRounded = false) => {
  const lastNDates = generateLastNdates(n, 'MM/dd/yyyy').reverse();
  const incomeOfNDay = lastNDates.map((date) => getIncomeForDate(incomes, date));
  return isRounded
    ? incomeOfNDay.map((inc) => Math.round(inc))
    : incomeOfNDay.map((inc) => parseFloat(inc.toFixed(3)));
};

const getBalanceLastNDay = (incomes, balance, n) => {
  let sumIncome = 0;
  const balances = [];

  const incomeOfNDay = getIncomeOfNDay(incomes, n).reverse();
  incomeOfNDay.forEach((inc, i) => {
    sumIncome += inc;
    balances.push(Math.round(balance - sumIncome + incomeOfNDay[i]));
  });

  return balances.reverse();
}

const getAccumulateProfitLastNDay = (incomes, balance, n) => {
  let sumIncome = 0;
  const balances = [];

  const incomeOfNDay = getIncomeOfNDay(incomes, n);
  incomeOfNDay.forEach((inc, i) => {
    sumIncome += inc;
    balances.push(Math.round(sumIncome));
  });

  return balances;
}

const PerformanceOverview = () => {
  const incomes = useRecoilValue(incomesAtom);
  const ownAccount = useRecoilValue(ownAccountAtom);
  const theme = useTheme();
  const [nDay, setNDay] = useState(30);

  const profitLastWeek = sum(
    flatten(Object.values(incomes))?.map((inc) => JSON.parse(inc?.income))
  );

  const { totalWalletBalance = 0 } = ownAccount;
  const balance = JSON.parse(totalWalletBalance);
  const increasePercent =
    (balance > 0 && profitLastWeek && (profitLastWeek / (balance - profitLastWeek)) * 100) || 0;

  const balancesOfLastNDay = getAccumulateProfitLastNDay(incomes, balance, nDay);
  console.log('@@@balancesOfLastNDay', balancesOfLastNDay)
  console.log('@@@lastNDates(nDay)', lastNDates(nDay))
  const NDayIncome = getIncomeOfNDay(incomes, nDay, false);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.chart.blue[0]],
    stroke: { width: [2] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: lastNDates(nDay),
    xaxis: {
      type: 'datetime',
      forceNiceScale: true,
      labels: {
        datetimeUTC: false
      }
    },
    yaxis: [
      // {
      //   seriesName: 'Income',
      //   opposite: true,
      //   title: 'Income',
      //   min: min(NDayIncome) * 1.05,
      //   max: max(NDayIncome) * 1.05,
      //   forceNiceScale: true
      // },
      {
        seriesName: 'Balance',
        min: min(balancesOfLastNDay) > 0 ? Math.ceil(min(balancesOfLastNDay) * 0.95) : Math.ceil(min(balancesOfLastNDay) * 1.05),
        max: max(balancesOfLastNDay) > 0 ? Math.ceil(max(balancesOfLastNDay) * 1.05) : Math.ceil(max(balancesOfLastNDay) * 0.95),
        forceNiceScale: true,
        title: 'Balance',
        decimalsInFloat: 0
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `$${y.toFixed(1)}`;
          }
          return y;
        }
      }
    }
  });

  const CHART_DATA = [
    // {
    //   name: 'Income',
    //   type: 'column',
    //   data: NDayIncome
    // },
    {
      name: 'Balance',
      type: 'area',
      data: balancesOfLastNDay
    }
  ];

  return (
    <Card>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label" xs={{ m: 2 }}>Interval</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={nDay}
          label="Interval"
          onChange={(event) => {
            setNDay(event.target.value)
          }}
        >
          <MenuItem value={3}>last 3 days</MenuItem>
          <MenuItem value={7}>last 7 days</MenuItem>
          <MenuItem value={15}>last 15 days</MenuItem>
          <MenuItem value={30}>last 30 days</MenuItem>
        </Select>
      </FormControl>
      <CardHeader
        title={`${nDay}天内的累计盈亏`}
      // subheader={`${fPercent(increasePercent, '0.00%')} last ${nDay} days`}
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={500} />
      </Box>
    </Card >
  );
};

export default PerformanceOverview;
