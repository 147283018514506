import axios from 'axios';
import { BINANCE_HEADERS, getSignature, getUrl } from './utils';

const UN_LOGIN_CODE = 2001

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数。
  if (response?.data?.code === UN_LOGIN_CODE) {
    window.location.href = '/loginPage'
  } else {
    return response;
  }
}, (error) =>
  // 超出 2xx 范围的状态码都会触发该函数。
  console.error(error)
);

export const getUserAccount = async () => {
  const timestamp = Date.now();

  const params = {
    timestamp,
    signature: getSignature(`timestamp=${timestamp}`)
  };

  const response = await axios.request({
    url: getUrl('account'),
    method: 'get',
    params,
    headers: BINANCE_HEADERS
  });

  return response?.data;
};

export const getUserNowAccount = async ({ accountId }) => {
  if (!accountId) {
    return []
  }
  const params = {
    accountId
  };

  const response = await axios.request({
    url: getUrl('nowAccount'),
    method: 'get',
    params
  });
  return response?.data?.data;
};

export const getCoinUserNowAccount = async ({ accountId }) => {
  if (!accountId) {
    return []
  }
  const params = {
    accountId
  };
  const response = await axios.request({
    url: getUrl('coinNowAccount'),
    method: 'get',
    params
  });
  return response?.data?.data;
};

export const getOwnUserAccount = async ({ accountId, startTimestamp, sample = 5 }) => {
  const params = {
    accountId,
    sample
  };
  if (startTimestamp) {
    params.startTimestamp = startTimestamp
  }

  const response = await axios.request({
    url: getUrl('ownAccount'),
    method: 'get',
    params,
  });


  return response?.data;
};

export const getOwnTotalWalletBalance = async ({ accountId, startTimestamp, sample = 5 }) => {
  const params = {
    accountId,
    sample
  };
  if (startTimestamp) {
    params.startTimestamp = startTimestamp
  }

  const response = await axios.request({
    url: getUrl('ownTotalWalletBalance'),
    method: 'get',
    params,
  });

  return response?.data;
};


export const getIds = async () => {
  const response = await axios.request({
    url: getUrl('ids'),
    method: 'get',
  });

  return response?.data;
};

export const getAccounts = async () => {
  const response = await axios.request({
    url: getUrl('accounts'),
    method: 'get',
  });

  return response?.data;
};


export const getCustomerDetail = async (id) => {
  const response = await axios.request({
    url: `${getUrl('customerDetail')}/${id}`,
    method: 'get',
  });
  return response?.data;
};

export const getCustomers = async () => {
  const response = await axios.request({
    url: getUrl('customerList'),
    method: 'get',
  });
  return response?.data;
};

export const getCustomerTotal = async ({ customerId, startTime, endTime }) => {
  const response = await axios.request({
    url: `${getUrl('customerTotal')}`,
    method: 'post',
    data: { customerId, startTime, endTime }
  });
  return response?.data;
};

export const currentDetail = async (accountId) => {
  if (!accountId) {
    return ({ data: [] })
  }
  const response = await axios.request({
    url: `${getUrl('currentDetail')}`,
    params: { accountId },
    method: 'get',
  });
  return response?.data;
};


export const login = async ({ username, password }) => {
  const bodyFormData = new FormData();
  bodyFormData.append('username', username)
  bodyFormData.append('password', password)
  const response = await axios.request({
    url: `${getUrl('login')}`,
    method: 'post',
    data: bodyFormData
  });

  return response?.data;
};
// transfer
export const transfer = async ({ accountId, type, asset, amount }) => {
  if (!accountId) {
    return { msg: "请选择交易账户" }
  }

  const param = {
    accountId,
    type,
    asset,
    amount
  }
  const response = await axios.request({
    url: `${getUrl('transfer')}`,
    method: 'post',
    data: param
  });

  return response?.data
}





