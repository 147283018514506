import { merge, sum, max, flatten, min } from 'lodash';
import ReactApexChart from 'react-apexcharts';

// material
import { Card, CardHeader, Grid, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from './index';
import { generateLastNdates, fCDateTime } from '../../utils/formatTime';
import { fNumber, fCurrency } from '../../utils/formatNumber';
import SummaryBox from '../_dashboard/SummaryBox';

const CHART_HEIGHT = 250;
const LEGEND_HEIGHT = 30;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(3),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT, userSelect: 'text' },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible'
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
    }
}));

const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;

const SymbolTradesAnalysis = (props) => {
    const theme = useTheme();
    const { interval } = props;
    const filterTrades = props.symbolTrades.filter((item) => item.time > new Date().getTime() - interval * ONE_DAY_TIMESTAMP)
    const buyTrades = filterTrades.filter((item) => item.side === 'BUY')
    const buyTimes = buyTrades.length;
    const sellTrades = filterTrades.filter((item) => item.side === 'SELL')
    const sellTimes = sellTrades.length;
    const buyWinTimes = filterTrades.filter((item) => item.side === 'BUY' && item.realizedPnl > 0).length
    const sellWinTimes = filterTrades.filter((item) => item.side === 'SELL' && item.realizedPnl > 0).length
    const analysisData = {
        totalTrades: filterTrades.length,
        buyTimes,
        sellTimes,
        buyWinRate: ((buyWinTimes / buyTimes) * 100).toFixed(1),
        sellWinRate: ((sellWinTimes / sellTimes) * 100).toFixed(1),
    }

    const series = [analysisData.buyTimes, analysisData.sellTimes]

    // const series = [44, 55, 13, 43, 22]

    const chartOptions = merge(BaseOptionChart(), {
        labels: ["买空", "买多"],
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: 'center' },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `${seriesName}`
                }
            }
        },
        plotOptions: {
            pie: { donut: { labels: { show: false } } }
        }
    })

    const buyWinRateChartOption = merge(BaseOptionChart(), {
        series: [analysisData.buyWinRate],
        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -20
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '16px',
                        color: undefined,
                        offsetY: 120
                    },
                    value: {
                        offsetY: 76,
                        fontSize: '22px',
                        color: undefined,
                        formatter(val) {
                            return `${val}%`;
                        }
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: ['买多胜率'],
    })

    const sellWinRateChartOption = {
        ...buyWinRateChartOption,
        series: [analysisData.sellWinRate],
        colors: [theme.palette.chart.blue[0]],
        labels: ['买空胜率'],
    }

    const buyTotal = sum(buyTrades.map((o) => o.realizedPnl))
    const sellTotal = sum(sellTrades.map((o) => o.realizedPnl))
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4} >
                <Card>
                    <CardHeader title="历史多空占比" />
                    <ChartWrapperStyle dir="ltr">
                        <ReactApexChart type="pie" series={series} options={chartOptions} height={200} />
                    </ChartWrapperStyle>
                </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={4} >
                <Card sx={{ py: 13 }}>
                    <SummaryBox
                        text="做空盈利"
                        total={fCurrency(buyTotal)}
                        color={buyTotal > 0 ? 'success' : 'error'}
                        backgroundColor={buyTotal > 0 ? 'success' : 'error'}
                    />
                    {/* <CardHeader title="做多胜率"
                        subheader={`${fCurrency(parseInt(sum(buyTrades.map((o) => o.realizedPnl)), 10))} last ${interval} days`}
                    />
                    <ReactApexChart options={buyWinRateChartOption} series={[analysisData.buyWinRate]} type="radialBar" height={220} /> */}
                </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={4} >
                <Card sx={{ py: 13 }}>
                    <SummaryBox
                        text="做多盈利"
                        total={fCurrency(sellTotal)}
                        color={sellTotal > 0 ? 'success' : 'error'}
                        backgroundColor={sellTotal > 0 ? 'success' : 'error'}
                    />
                    {/* <CardHeader title="做空胜率"
                        subheader={`${fCurrency(parseInt(sum(sellTrades.map((o) => o.realizedPnl)), 10))} last ${interval} days`}
                    />
                    <ReactApexChart options={sellWinRateChartOption} series={[analysisData.sellWinRate]} type="radialBar" height={220} /> */}
                </Card>
            </Grid>
        </Grid>
        // <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        // </Box>
    )
}


export default SymbolTradesAnalysis