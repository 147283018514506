import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardApp from './pages/DashboardApp';
import DashboardWrapper from './pages/DashboardWrapper';
import OrderHistory from './pages/OrderHistory';
import SymbolIncome from './pages/SymbolIncome';
import SimPerformance from './pages/SimPerformance';
import SimStatistics from './pages/SimStatistics';
import NotFound from './pages/Page404';
import Login from './pages/Login'
import Overview from './pages/Overview'
import Trade from './pages/Trade'
import Admin from './pages/Admin'
import IncomeHistory from './pages/IncomeHistory';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'app',
          element: <DashboardWrapper />
        },
        { path: 'orderHistory', element: <OrderHistory /> },
        { path: 'symbolIncome', element: <SymbolIncome /> },
        { path: 'simPerformance', element: <SimPerformance /> },
        { path: 'simStatistics', element: <SimStatistics /> }
      ]
    },
    {
      path: 'loginPage',
      element: <Login />
    },
    {
      path: 'overview',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Overview /> }
      ]
    },
    {
      path: 'admin',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Admin /> }
      ]
    },
    {
      path: 'trade',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Trade /> }
      ]
    },
    {
      path: 'incomeHistory',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <IncomeHistory /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/overview" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
