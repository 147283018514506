import { atom } from 'recoil';

const ownAccountAtom = atom({
  key: 'ownAccountState',
  default: {
    totalWalletBalance: 0
  }
});

export default ownAccountAtom;
