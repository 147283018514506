import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { format } from 'date-fns';

// routes
import { ConfirmProvider } from 'material-ui-confirm';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// recoil and services


const App = () => {
  useEffect(() => {
    console.log('Fetching your weekly data');
  }, []);

  return (
    <ThemeConfig>
      <ConfirmProvider>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ConfirmProvider>
    </ThemeConfig>
  );
};

export default App;