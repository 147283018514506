import axios from 'axios';
import { BINANCE_HEADERS, getSignature, getUrl } from './utils';

const UN_LOGIN_CODE = 2001

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    // 2xx 范围内的状态码都会触发该函数。
    if (response?.data?.code === UN_LOGIN_CODE) {
        window.location.href = '/loginPage'
    } else {
        return response;
    }
}, (error) =>
    // 超出 2xx 范围的状态码都会触发该函数。
    console.error(error)
);


export const getCustomerAccount = async (id) => {
    const response = await axios.request({
        url: `${getUrl('customerAccount')}/${id}`,
        method: 'get',
    });

    return response?.data;
};
export const createAccount = async (newAccount) => {

    const response = await axios.request({
        url: `${getUrl('createAccount')}`,
        method: 'post',
        data: newAccount
    });

    return response?.data
}

export const updateAccount = async (newAccount) => {
    try {
        const response = await axios.request({
            url: `${getUrl('updateAccount')}`,
            method: 'post',
            data: newAccount
        });
        return response?.data
    } catch (e) {
        return new Error(e)
    }

}

export const resetAccount = async (accountId) => {

    const response = await axios.request({
        url: `${getUrl('resetAccount')}`,
        method: 'post',
        params: { accountId },
        // data: { accountId }
    });

    return response?.data
}

export const clearAccount = async (accountId) => {

    const response = await axios.request({
        url: `${getUrl('clearAccount')}`,
        method: 'post',
        params: { accountId },
    });

    return response?.data
}

export const createCustomer = async (name) => {

    const response = await axios.request({
        url: `${getUrl('createCustomer')}`,
        method: 'post',
        params: { name },
    });

    return response?.data
}

export const clearCustomer = async (customerId) => {

    const response = await axios.request({
        url: `${getUrl('clearCustomer')}`,
        method: 'post',
        params: { customerId },
    });

    return response?.data
}

export const getAllCustomers = async () => {
    const response = await axios.request({
        url: getUrl('customerListAll'),
        method: 'get',
    });
    return response?.data;
};

export const getAllUser = async () => {
    const response = await axios.request({
        url: getUrl('userList'),
        method: 'get',
        params: { customerId: 1 },
    });
    return response?.data;
};
export const customerUserRelation = async (customerId) => {

    const response = await axios.request({
        url: `${getUrl('customerUserRelation')}`,
        method: 'get',
        params: { customerId },
    });

    return response?.data
}
export const customerAssign = async ({ customerId, userIds }) => {

    const response = await axios.request({
        url: `${getUrl('customerAssign')}`,
        method: 'post',
        data: { customerId, userIds }
    });

    return response?.data
}
// transfer
// export const transfer = async ({ accountId, type, asset, amount }) => {
//   const param = {
//     accountId,
//     type,
//     asset,
//     amount
//   }
//   const response = await axios.request({
//     url: `${getUrl('transfer')}`,
//     method: 'post',
//     data: param
//   });

//   return response.data
// }





