import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(parseFloat(date)), 'dd MMMM yyyy');
}
export function fCTime(date) {
  return format(new Date(parseFloat(date)), 'yyyy/MM/dd');
}
export function fDateTime(date) {
  return format(new Date(parseFloat(date)), 'dd MMM yyyy HH:mm');
}

export function fCDateTime(date) {
  return format(new Date(parseFloat(date)), 'yyyy/MM/dd HH:mm');
}

export function fTime(date) {
  return format(new Date(parseFloat(date)), 'HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(parseFloat(date)), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(parseFloat(date)), {
    addSuffix: true
  });
}

export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const generateLastNdates = (n, f = null) =>
  [...Array(n)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - i);
    return f ? format(new Date(d), f) : d;
  });

export const generateLastNweeks = (n, f = null) =>
  [...Array(n)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - 7 * i);
    return f ? format(new Date(d), f) : d;
  });
export const formatDuring = (millisecond = 0) => {
  const days = parseInt(millisecond / (1000 * 60 * 60 * 24), 10);
  const hours = parseInt((millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60), 10);
  const minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60), 10);
  const seconds = (millisecond % (1000 * 60)) / 1000;
  if (days > 0) {
    return `${days} 天 ${hours} 小时 `;
  } if (days === 0) {
    return `${hours} 小时 `;
  }
}
