import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, Typography, Switch, Box, ButtonGroup, Button } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Icon } from '@iconify/react';

// material
import { styled } from '@mui/material/styles';
//
import { fontSize } from '@mui/system';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { fCDateTime } from '../../utils/formatTime';
import { updateTimeAtom, themeAtom, accountIdAtom, idsAtom } from '../../recoil/atoms';
import { getIds } from '../../services/accountServices';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 16;
const APP_BAR_DESKTOP = 8;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const setAccountIdAtom = useSetRecoilState(accountIdAtom);
  const accountId = useRecoilValue(accountIdAtom)
  const [open, setOpen] = useState(false);
  // const updateTime = useRecoilValue(updateTimeAtom);
  const theme = useRecoilValue(themeAtom);
  const [checked, setChecked] = useState(theme === 'dark');
  // const [ids, setIds] = useState([]);
  const setThemeRecoil = useSetRecoilState(themeAtom);
  const setIdsRecoil = useSetRecoilState(idsAtom);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setThemeRecoil(event.target.checked ? 'dark' : 'white')
  };

  useEffect(() => {
    getIds().then(({ data }) => setIdsRecoil(data))
  }, [])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Grid container justifyContent="flex-end">
          <Grid item xs={1} justifyContent="flex-end" alignItems="center" display="flex">
            <Icon icon="fluent:dark-theme-20-filled" style={{ fontSize: '25px' }} />
            <Switch checked={checked} onChange={handleChange} color="secondary" />
          </Grid>
        </Grid>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
