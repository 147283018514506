import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Box,
    Tab,
    Tabs,
    Button,
    Fab,
    Modal,
    CardHeader,
    TextField,
    Snackbar,
    Alert,
    Select,
    Chip,
    MenuItem,
    OutlinedInput,
    FormControl,
    InputLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AddIcon from '@mui/icons-material/Add';
// components
import { useTheme } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useConfirm } from 'material-ui-confirm';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { OrderListHead, OrderListToolbar } from '../components/_dashboard/order';
import { customerAssign, getAllUser, getCustomerAccount, createAccount, updateAccount, resetAccount, clearAccount, createCustomer, getAllCustomers, clearCustomer, customerUserRelation } from '../services/adminServices'
import { getAccounts } from '../services/accountServices'


//

// import { tradesAtom } from '../recoil/atoms';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    pb: 4,
    px: 2,
    maxHeight: '90vh',
    overflow: 'auto',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const TABLE_HEAD = [
    { id: 'source', label: '交易所' },
    { id: 'name', label: '账户名' },
    { id: 'apiKey', label: 'apiKey' },
    { id: 'apiSecret', label: 'apiSecret' },
    { id: 'spotBaseAmount', label: '现货初始资金' },
    { id: 'swapBaseAmount', label: '合约初始资金' },
    { id: 'coinBaseAmount ', label: '币本位初始资金' },
    { id: 'startTime', label: '开始时间' },
    { id: 'mainAccountName', label: '主账户名称' },
    { id: 'followType', label: '跟随方向' },
    { id: 'remark', label: '备注' },
    { id: 'action', label: '操作' }
];
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];
// ---------------------------------------------------------------------


const Overview = () => {
    const theme = useTheme();
    const confirm = useConfirm();
    const [accountList, setAccountList] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(null)
    const [open, setOpen] = useState(false)
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [success, setSuccess] = useState(true)
    const [msg, setMsg] = useState('')
    const [createCustomerOpen, setCreateCustomerOpen] = useState(false)
    const [createCustomerName, setCreateCustomerName] = useState('')
    const [users, setUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [ids, setIds] = useState([])

    const [accountDetail, setAccountDetail] = useState({
        name: '',
        apiKey: '',
        apiSecret: '',
        spotBaseAmount: '',
        swapBaseAmount: '',
        coinBaseAmount: '',
        startTime: new Date(),
        remark: '',
        source: '',
        passPhrase: '',
        mainAccountId: '',
        id: '',
        followType: null
    })
    const handleMultiChange = (event) => {
        const {
            target: { value },
        } = event;
        setUserIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleMultiClose = async () => {
        try {
            const res = await customerAssign({ customerId, userIds })
            if (res.code === 2000) {
                setMsg('设置可见用户成功')
                setSuccess(true)
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            queryCustomerAccount()
        }
    };
    useEffect(() => {
        queryCustomer()
        getAllUser().then(({ data }) => {
            setUsers(data)
            if (customerId) {
                queryCustomerUserRelation()
            }
        })
        getAccounts().then(({ data }) => {
            setIds([{ name: '无', id: '' }, ...data])
        })
    }, [])

    useEffect(() => {
        if (customerId) {
            queryCustomerAccount()
            queryCustomerUserRelation(customerId)
        }
    }, [customerId])

    const queryCustomerUserRelation = () => {
        customerUserRelation(customerId).then(({ data }) => {
            setUserIds(data)
        })
    }

    const queryCustomerAccount = () => {
        getCustomerAccount(customerId).then(({ data }) => {
            setAccountList(data)
        })
    }
    const queryCustomer = () => {
        getAllCustomers().then(({ data }) => {
            setCustomers(data)
            setCustomerId(data[0].id)
        })
    }
    const handleChange = (event, newValue) => {
        setCustomerId(newValue);
    };

    const handleCloseModal = () => {
        setOpen(false)
        setAccountDetail({
            name: '',
            apiKey: '',
            apiSecret: '',
            spotBaseAmount: '',
            swapBaseAmount: '',
            coinBaseAmount: '',
            startTime: new Date(),
            remark: '',
            source: '',
            passPhrase: ''
        })
    }

    const handleAddAccount = () => {
        setIsAdd(true)
        setOpen(true)
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    const handleEdit = (row) => {
        setIsAdd(false)
        setOpen(true)
        setAccountDetail({
            ...row
        })
    }
    const handleReset = async (id) => {
        confirm({
            description: `警告：此操作将以当前时间为起点重新开始计算账户收益，即：
            1.设置账户开始时间置为当前时间
            2.设置现货初始资金和期货初始资金为当前账户实际拥有的资金
            3.清空历史收益数据，重新开始计算收益率等指标
            `,
            title: "确认重算收益",
            confirmationText: "确定",
            cancellationText: "取消"
        })
            .then(async () => {
                try {
                    const res = await resetAccount(id)
                    if (res.code === 2000) {
                        setMsg('重算收益成功')
                        setSuccess(true)
                        queryCustomerAccount()
                    } else {
                        setMsg(res.msg)
                        setSuccess(false)
                    }
                } catch (e) {
                    setMsg(e)
                    setSuccess(false)
                } finally {
                    setSnackbarOpen(true)
                }
            })
    }

    const handleClear = async (row) => {
        confirm({ description: '警告：删除账户将会清空账户所有数据, 此操作不可逆！！！', title: `确认要删除账户:[${row.name}]吗?`, confirmationText: "确定", cancellationText: "取消" })
            .then(async () => {
                try {
                    const res = await clearAccount(row.id)
                    if (res.code === 2000) {
                        setMsg('删除成功')
                        setSuccess(true)
                    } else {
                        setMsg(res.msg)
                        setSuccess(false)
                    }
                } catch (e) {
                    setMsg(e)
                    setSuccess(false)
                } finally {
                    setSnackbarOpen(true)
                    queryCustomerAccount()
                }
            })
    }

    const handleSubmit = async () => {
        // 如果选择了主账号，必须选择跟随方向
        if (!!accountDetail.mainAccountId && !accountDetail.followType) {
            setMsg('选择了主账号，必须选择跟随方向')
            setSuccess(false)
            setSnackbarOpen(true)
            return
        }
        const newAccount = {
            ...accountDetail,
            customerId
        }
        if (accountDetail.source !== 'OKX') {
            delete newAccount.passPhrase
        }
        try {
            setLoading(true)
            let res
            if (isAdd) {
                res = await createAccount(newAccount)
            } else {
                delete newAccount.apiKey
                delete newAccount.apiSecret
                res = await updateAccount(newAccount)
            }
            if (res.code === 2000) {
                if (isAdd) {
                    setMsg('新增账户成功')
                } else {
                    setMsg('修改账户成功')
                }
                setSuccess(true)
                setOpen(false)
                queryCustomerAccount()
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }
    const handleCreateCustomer = async () => {
        try {
            const res = await createCustomer(createCustomerName)
            if (res.code === 2000) {
                setMsg('新增客户成功')
                setSuccess(true)
                handleCloseCreateModal()
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            queryCustomer()
        }
    }
    const handleAddCustomer = () => {
        setCreateCustomerOpen(true)
    }
    const handleCloseCreateModal = () => {
        setCreateCustomerOpen(false)
        setCreateCustomerName('')
    }
    const handleDeleteCustomer = () => {
        const customer = customers.find((item) => item.id === customerId)
        confirm({ description: '警告：删除客户将会清空客户所有数据, 此操作不可逆！！！', title: `确认要删除客户:[${customer.name}]吗?`, confirmationText: "确定", cancellationText: "取消" })
            .then(async () => {
                try {
                    const res = await clearCustomer(customerId)
                    if (res.code === 2000) {
                        setMsg('删除成功')
                        setSuccess(true)
                        queryCustomer()
                    } else {
                        setMsg(res.msg)
                        setSuccess(false)
                    }
                } catch (e) {
                    setMsg(e)
                    setSuccess(false)
                } finally {
                    setSnackbarOpen(true)
                }
            })
    }
    const renderUserSelect = () => (
        <FormControl sx={{ my: 2, width: '100%' }}>
            <InputLabel id="demo-multiple-chip-label">可见用户</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={userIds}
                onChange={handleMultiChange}
                input={<OutlinedInput id="select-multiple-chip" label="可见用户" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {
                            selected.map((value) => {
                                const user = users.find((u) => u.id === value)
                                if (user) {
                                    return (<Chip key={user.id} label={user.name} />)
                                }
                                return ''
                            })
                        }
                    </Box>
                )}
                MenuProps={MenuProps}
                onClose={handleMultiClose}
            >
                {users.map((user) => (
                    <MenuItem
                        key={user.id}
                        value={user.id}
                    >
                        {user.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
    return (
        <Page title="客户概览页">
            <Container style={{ maxWidth: '1600px' }}>
                <Box sx={{ mb: 1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Tabs value={customerId} onChange={handleChange}>
                            {
                                customers.map((item) => (<Tab style={{ fontSize: '20px' }} label={item.name} key={item.id} value={item.id} />))
                            }
                        </Tabs>
                        <Stack direction="row" spacing={3} alignItems="center" >
                            <LoadingButton size='small' sx={{ height: "50px" }} loading={loading} variant="contained" onClick={handleDeleteCustomer}>删除当前客户</LoadingButton>
                            <Button size='small' sx={{ height: "50px" }} variant="contained" onClick={handleAddCustomer}>新增客户</Button>
                        </Stack>
                    </Stack>
                </Box>
                <Card sx={{ p: 3 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h5" gutterBottom>
                            账户管理
                        </Typography>
                        <Button color="primary" onClick={handleAddAccount}>新增账户</Button>
                    </Stack>

                    <Card>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <OrderListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={accountList.length}
                                        disabled
                                    />
                                    <TableBody>
                                        {accountList.map((row) => {
                                            const { source, id, name, apiKey, apiSecret, spotBaseAmount, swapBaseAmount, coinBaseAmount, startTime, remark, followType, mainAccountId } = row;
                                            const account = ids.find((account) => account.id === mainAccountId)
                                            const mainAccountName = account?.name || ''
                                            let followTypeName = ''
                                            if (followType === 'FORWARD') {
                                                followTypeName = '正向'
                                            } else if (followType === 'REVERSE') {
                                                followTypeName = '反向'
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                >
                                                    <TableCell align="center">{source}</TableCell>
                                                    <TableCell align="center" style={{ cursor: 'pointer' }} >
                                                        <Typography variant="subtitle2" noWrap>
                                                            {name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">{apiKey}</TableCell>
                                                    <TableCell align="center">{apiSecret}</TableCell>
                                                    <TableCell align="center" >{spotBaseAmount}</TableCell>
                                                    <TableCell align="center" >{swapBaseAmount}</TableCell>
                                                    <TableCell align="center" >{coinBaseAmount}</TableCell>
                                                    <TableCell align="center" >{startTime}</TableCell>
                                                    <TableCell align="center" >{mainAccountName}</TableCell>
                                                    <TableCell align="center" >{followTypeName}</TableCell>
                                                    <TableCell align="center">{remark}</TableCell>
                                                    <TableCell align="center">
                                                        <Button onClick={() => handleEdit(row)}>编辑</Button>
                                                        <Button onClick={() => handleReset(id)}>重算收益</Button>
                                                        <Button onClick={() => handleClear(row)}>删除</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Card>
                </Card>
                {renderUserSelect()}
            </Container>
            <Modal
                open={open}
                onClose={handleCloseModal}
            >
                <Card sx={style}  >
                    <CardHeader
                        title={isAdd ? "新增账户" : "编辑账户"}
                    />
                    <Stack spacing={2} sx={{ m: 3 }}>
                        <TextField
                            fullWidth
                            label="账户名"
                            id="fullWidth"
                            value={accountDetail.name}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, name: value })}
                        />
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">所属交易所</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={accountDetail.source}
                                onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, source: value })}
                                label="account"
                                disabled={!isAdd}
                            >
                                <MenuItem value="BINANCE" >BINANCE</MenuItem>
                                <MenuItem value="OKX" >OKX</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">模式</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={accountDetail.mode}
                                onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, mode: value })}
                                label="account"
                            >
                                <MenuItem value="PM" >PM</MenuItem>
                                <MenuItem value="COMMON" >COMMON</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="apiKey"
                            id="fullWidth"
                            value={accountDetail.apiKey}
                            disabled={!isAdd}
                            sx={{ my: 2 }}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, apiKey: value })}
                        />
                        <TextField
                            fullWidth
                            label="apiSecret"
                            id="fullWidth"
                            disabled={!isAdd}
                            value={accountDetail.apiSecret}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, apiSecret: value })}
                        />
                        {
                            accountDetail.source === 'OKX' &&
                            <TextField
                                fullWidth
                                label="密码"
                                id="fullWidth"
                                disabled={!isAdd}
                                value={accountDetail.passPhrase}
                                onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, passPhrase: value })}
                            />
                        }
                        <TextField
                            fullWidth
                            label="现货初始资金"
                            id="fullWidth"
                            value={accountDetail.spotBaseAmount}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, spotBaseAmount: value })}
                        />
                        <TextField
                            fullWidth
                            label="合约初始资金"
                            id="fullWidth"
                            value={accountDetail.swapBaseAmount}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, swapBaseAmount: value })}
                        />
                        <TextField
                            fullWidth
                            label="币本位初始资金"
                            id="fullWidth"
                            value={accountDetail.coinBaseAmount}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, coinBaseAmount: value })}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="开始时间"
                                inputFormat="yyyy-MM-dd"
                                value={accountDetail.startTime}
                                onChange={(value) => setAccountDetail({ ...accountDetail, startTime: value })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">所属主账号</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth-label"
                                value={accountDetail.mainAccountId}
                                onChange={({ target: { value: id } }) => {
                                    if (!id) {
                                        setAccountDetail({ ...accountDetail, mainAccountId: id, followType: null })
                                    } else {
                                        setAccountDetail({ ...accountDetail, mainAccountId: id })
                                    }
                                }}
                                label="account"
                            >
                                {
                                    ids?.map((account) => (
                                        account.id !== accountDetail.id ? <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem> : null
                                    ))
                                }
                            </Select>
                        </FormControl>
                        {accountDetail.mainAccountId &&
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <InputLabel id="demo-simple-select-autowidth-label">跟单方向</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth-label"
                                    value={accountDetail.followType}
                                    onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, followType: value })}
                                    label="account"
                                >
                                    <MenuItem value='FORWARD' key='FORWARD'>正向</MenuItem>
                                    <MenuItem value='REVERSE' key='REVERSE'>反向</MenuItem>
                                </Select>
                            </FormControl>
                        }
                        <TextField
                            id="outlined-multiline-static"
                            label="备注"
                            multiline
                            rows={3}
                            value={accountDetail.remark}
                            onChange={({ target: { value } }) => setAccountDetail({ ...accountDetail, remark: value })}
                        />
                    </Stack>
                    <Stack justifyContent="flex-end" direction="row">
                        <LoadingButton loading={loading}
                            variant="contained" onClick={handleSubmit}>确定</LoadingButton>
                    </Stack>
                </Card>
            </Modal>
            {/* 提示框 */}
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={3000} onClose={handleSnackbarClose}
            >

                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>

            <Modal
                open={createCustomerOpen}
                onClose={handleCloseCreateModal}
            >
                <Card sx={style}  >
                    <CardHeader
                        title="新增客户"
                    />
                    <Stack sx={{ m: 3 }} spacing={3}>
                        <TextField
                            fullWidth
                            label="客户名称"
                            id="fullWidth"
                            value={createCustomerName}
                            onChange={({ target: { value } }) => setCreateCustomerName(value)}
                        />
                        <Stack justifyContent="flex-end" direction="row">
                            <LoadingButton loading={loading}
                                variant="contained" onClick={handleCreateCustomer}>确定</LoadingButton>
                        </Stack>
                    </Stack>
                </Card>
            </Modal>

        </Page >
    );
};

export default Overview;
