import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loginAtom } from '../recoil/atoms';
import {login} from '../services/accountServices'


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            .
        </Typography>
    );
}

const theme = createTheme();

export default function SignInSide() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(true)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const account = localStorage.getItem('account')
    const password = localStorage.getItem('password')
    const [tipMsg,setTipMsg] = useState('')
    const setLoginAtom = useSetRecoilState(loginAtom);

    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const code = data.get('code')
        if(code.length !== 6){
            setTipMsg('The code must be six digits') 
            setOpen(true) 
            return 
        }
        const res = await login({username: data.get('email'), password: `${data.get('password')},${code}` })
        if(res.code === 2000){
            if (checked) {
                localStorage.setItem("account", data.get('email'));
                localStorage.setItem("password", data.get('password'));
                localStorage.setItem("isLogin", true);
            }
            setLoginAtom(true)
            setTimeout(() => {
                navigate("/overview");
            }, 0)
        }else{
            setTipMsg('This password or code is incorrect. Please enter the correct one.')
            setOpen(true) 
        }
    };

    const handleCheckboxOnchange = (event) => {
        setChecked(event.target.checked)
        if (!event.target.checked) {
            localStorage.removeItem('account')
            localStorage.removeItem('password')
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} >
                    Incorrect
                </DialogTitle>
                <DialogContent sx={{ width: '400px', height: '80px' }}>
                    <DialogContentText>
                        {tipMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 25,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            {/* <LockOutlinedIcon /> */}
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="account"
                                name="email"
                                autoComplete="email"
                                defaultValue={account}
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                defaultValue={password}
                                autoComplete="current-password"
                            />
                             <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="code"
                                label="code"
                                type="code"
                                id="code"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={checked} color="primary" onChange={handleCheckboxOnchange} />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        Don't have an account? Sign Up
                                    </Link>
                                </Grid>
                            </Grid> */}
                            {/* <Copyright sx={{ mt: 5 }} /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}