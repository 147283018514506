
import { merge, sum, max, flatten, min } from 'lodash';
import ReactApexChart from 'react-apexcharts';

// material
import { Card, CardHeader, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from './index';
import { generateLastNdates, fCDateTime } from '../../utils/formatTime';

const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    '& .apexcharts-canvas svg': { userSelect: 'text' },
}));

const SymbolLine = (props) => {
    const theme = useTheme();
    const { interval = Number.MAX_SAFE_INTEGER, onChange, showInterval = true } = props;
    const unit = props.unit || '$'
    const filterData = props.incomeData.filter((item) => item.time > new Date().getTime() - interval * ONE_DAY_TIMESTAMP)
    const chartData = filterData.map((item) => parseFloat((item.balance - filterData[0].balance).toFixed(2)))
    const labelData = filterData.map((item) => item.time)
    const CHART_DATA = [
        {
            name: 'Balance',
            type: 'area',
            data: chartData
        },
    ];

    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {
            type: 'datetime',
            forceNiceScale: true,
            labels: {
                datetimeUTC: false
            }
        },
        // xaxis,
        chart: {
            height: 350,
            type: 'area'
        },
        labels: labelData,
        yaxis: [
            {
                seriesName: 'Balance',
                min: min(chartData) > 0 ? Math.ceil(min(chartData) * 0.95) : Math.ceil(min(chartData) * 1.05),
                max: max(chartData) > 0 ? Math.ceil(max(chartData) * 1.05) : Math.ceil(max(chartData) * 0.95),
                forceNiceScale: true,
                title: 'Balance',
                decimalsInFloat: 0
            }
        ],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${unit}${y.toFixed(1)}`;
                    }
                    return y;
                }
            },
            x: {
                show: true,
                shared: true,
                formatter: (x) => {
                    if (typeof x !== 'undefined') {
                        return `${fCDateTime(x)}`;
                    }
                    return x;
                }
            }
        }
    });
    useEffect(() => {

    }, [])
    return (
        <ChartWrapperStyle>
            {showInterval &&
                <Select
                    value={interval}
                    onChange={(event) => {
                        onChange(event.target.value)
                    }}
                    style={{ position: 'absolute', zIndex: 100, top: '12px', right: '24px', color: `${theme.palette.primary.link}` }}>
                    <MenuItem value={1}>today</MenuItem>
                    <MenuItem value={3}>last 3 days</MenuItem>
                    <MenuItem value={7}>last 7 days</MenuItem>
                    <MenuItem value={15}>last 15 days</MenuItem>
                    <MenuItem value={30}>last 30 days</MenuItem>
                    <MenuItem value={10000}>all</MenuItem>
                </Select>
            }
            <CardHeader
                title={props.title || `${props.symbol}的收益曲线`}
                subheader={props.subTitle || ''}
            />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart type="area" series={CHART_DATA} options={chartOptions} height={350} />
            </Box>
        </ChartWrapperStyle>
    );
}

export default SymbolLine