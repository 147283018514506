import { merge, sum, max, flatten, min } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { useTheme, styled } from '@mui/material/styles';

// material
import { Card, CardHeader, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { BaseOptionChart } from '../../charts';
import { ownWalletBalance } from '../../../recoil/atoms';
import { fCDateTime } from '../../../utils/formatTime'

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    '& .apexcharts-canvas svg': { userSelect: 'text' },
}));


const WalletBalance = () => {
    // const incomes = useRecoilValue(incomesAtom);
    const walletBalance = useRecoilValue(ownWalletBalance);
    const walletOfLastNDay = walletBalance.map(item => item.totalWalletBalance)
    const time = walletBalance.map(item => item.time)
    const theme = useTheme();
    // const [nDay, setNDay] = useState(30);

    const chartOptions = merge(BaseOptionChart(), {
        colors: [theme.palette.chart.green[0]],
        stroke: { width: [2] },
        plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
        fill: { type: ['solid', 'gradient', 'solid'] },
        labels: time,
        xaxis: {
            type: 'datetime',
            forceNiceScale: true,
            labels: {
                datetimeUTC: false
            }
        },
        yaxis: [
            {
                seriesName: 'Balance',
                min: min(walletOfLastNDay) > 0 ? Math.ceil(min(walletOfLastNDay) * 0.95) : Math.ceil(min(walletOfLastNDay) * 1.05),
                max: max(walletOfLastNDay) > 0 ? Math.ceil(max(walletOfLastNDay) * 1.05) : Math.ceil(max(walletOfLastNDay) * 0.95),
                forceNiceScale: true,
                title: 'Balance',
                decimalsInFloat: 0
            }
        ],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `$${y.toFixed(1)}`;
                    }
                    return y;
                }
            },
            x: {
                show: true,
                shared: true,
                formatter: (x) => {
                    if (typeof x !== 'undefined') {
                        return `${fCDateTime(x)}`;
                    }
                    return x;
                }
            }

        }
    });

    const CHART_DATA = [
        {
            name: 'Balance',
            type: 'area',
            data: walletOfLastNDay
        }
    ];

    return (
        <ChartWrapperStyle>
            <Card>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    {/* <InputLabel id="demo-simple-select-label" xs={{ m: 2 }}>Interval</InputLabel> */}
                    {/* <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nDay}
                    label="Interval"
                    onChange={(event) => {
                        setNDay(event.target.value)
                    }}
                >
                    <MenuItem value={3}>last 3 days</MenuItem>
                    <MenuItem value={7}>last 7 days</MenuItem>
                    <MenuItem value={15}>last 15 days</MenuItem>
                    <MenuItem value={30}>last 30 days</MenuItem>
                </Select> */}
                </FormControl>
                <CardHeader
                    title="余额变化"
                // subheader={`${fPercent(increasePercent, '0.00%')} last ${nDay} days`}
                />
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                    <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={550} />
                </Box>
            </Card >
        </ChartWrapperStyle>
    );
};

export default WalletBalance;
