// material
import { styled, useTheme } from '@mui/material/styles';
import { Modal as MtdModal } from 'antd';
import {
    Snackbar,
    InputAdornment,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    Tab,
    CircularProgress,
    Alert,
    Fab,
    CardHeader,
    TextField,
    Modal,
    Select,
    MenuItem,
    Button,
    Typography
    , Container,
    Card,
    InputLabel,
    Chip,
    Stack,
    Grid,
    Divider,
    FormControl,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Table,
    Slider,
    Radio,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Switch
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { set } from 'date-fns';
import { Icon } from '@iconify/react';
import flipFill from '@iconify/icons-eva/flip-fill';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import ReactSelectMaterialUi from 'react-select-material-ui';
import Scrollbar from '../components/Scrollbar';
import { idsAtom } from '../recoil/atoms';
import { currentDetail, getAccounts, transfer, getUserNowAccount, getCoinUserNowAccount } from '../services/accountServices'
import { getOrderType, getTransferEnum, setDualSidePosition, getDualSidePosition, getSwapSymbols, getCoinSymbols, getSpotSymbols, getOpenOrders, makeOrder, setLeverage, cancelOrder, getPositionRisk, getAllOrders, calculateOrder } from '../services/tradesServices'
import { fCurrency, fNumber3, fPercent, fNumber2, fNumber4 } from '../utils/formatNumber';
import { MotionContainer, varBounceIn } from '../components/animate';
import { OrderListHead } from '../components/_dashboard/order';
import Page from '../components/Page';
import { fCDateTime } from '../utils/formatTime';
import RealTimePrice from '../components/RealTimePrice'
import AllOrders from './AllOrders';

const accountMap = {
    Dewave01: 'Account02',
    rioyu: 'T3',
    nate: 'Account02',
    Jeff: 'T3',
    admin: 'bale'
}

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    pb: 4,
    px: 2
};
const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));
const transferValueNameMap = {
    'MAIN': '现货账户',
    'UMFUTURE': '合约账户',
    'FUNDING': '资金账户',
    'PORTFOLIO_MARGIN': '统一账户'
}
const transferTypeMap = {
    'MAIN': ['UMFUTURE', 'FUNDING', 'PORTFOLIO_MARGIN'],
    'UMFUTURE': ['MAIN'],
    'FUNDING': ['MAIN'],
    'PORTFOLIO_MARGIN': ['MAIN']
}

const transferMap = {
    'spot': {
        name: '现货账户',
        tradeType: 1,
        availableFunds: 0,
        lockedAmount: 0,
    },
    'future': {
        name: 'U本位合约',
        tradeType: 2,
        availableFunds: 0,
        lockedAmount: 0,
    }
}
const TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    { id: 'type', label: '类型' },
    { id: 'side', label: '方向' },
    { id: 'price', label: '价格' },
    { id: 'amount', label: '仓位大小' },
    { id: 'cummulativeQuoteQty', label: '累计交易金额' },
    { id: 'completeness', label: "交易完成度" },
    // { id: 'amount', label: '金额' },
    { id: 'time', label: '时间' },
    { id: 'operation', label: '操作' },
];

const SWAP_POSITION_TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    // { id: 'notional', label: '持仓大小' },
    { id: 'openAmount', label: '开仓大小' },
    // { id: 'positionAmt', label: '持仓个数' },
    { id: 'side', label: "持仓方向" },
    { id: 'unrealizedProfit', label: '未实现盈亏' },
    { id: 'returenRadio', label: '回报率' },
    { id: 'positionInitialMargin', label: '保证金' },
    { id: 'entryPrice', label: '开仓价格' },
    { id: 'leverage', label: '杠杆' },
    { id: 'operation', label: '市价平仓' },
];
const COIN_POSITION_TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    // { id: 'notional', label: '持仓大小' },
    { id: 'openAmount', label: '开仓大小' },
    // { id: 'positionAmt', label: '持仓个数' },
    { id: 'side', label: "持仓方向" },
    // { id: 'unrealizedProfit', label: '未实现盈亏' },
    // { id: 'returenRadio', label: '回报率' },
    // { id: 'positionInitialMargin', label: '保证金' },
    // { id: 'entryPrice', label: '开仓价格' },
    { id: 'leverage', label: '杠杆' },
    // { id: 'operation', label: '市价平仓' },
];

const SWAP_ALL_ORDER_TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    { id: 'side', label: "类型" },
    { id: 'realizedProfit', label: '实现盈亏' },
    { id: 'positionAmt', label: '成交数量' },
    { id: 'avgPrice', label: '均价' },
    { id: 'time', label: '创建时间' },
];
// ----------------------------------------------------------------------
const orderTypeMap = {
    LIMIT: '限价单',
    MARKET: '市价单',
    TAKE_PROFIT_MARKET: "止盈",
    STOP_MARKET: "止损"
}
const spotOrderTypeList = [
    {
        "value": "LIMIT",
        "desc": "限价单"
    },
    {
        "value": "MARKET",
        "desc": "市价单"
    }
]

const coinOrderTypeList = [
    {
        "value": "LIMIT",
        "desc": "限价单"
    },
    {
        "value": "MARKET",
        "desc": "市价单"
    }
]

const dualTradeMap = {
    OPEN: {
        BUY: {
            positionSide: 'LONG',
            description: '开多'
        },
        SELL: {
            positionSide: 'SHORT',
            description: '开空'
        }
    },
    CLOSE: {
        BUY: {
            positionSide: 'SHORT',
            description: '平空'
        },
        SELL: {
            positionSide: 'LONG',
            description: '平多'
        }
    }
}

const stopList = [
    {
        "value": "TAKE_PROFIT_MARKET",
        "desc": "止盈"
    },
    {
        "value": "STOP_MARKET",
        "desc": "止损"
    }
]

const swapOrderTypeList = [
    {
        "value": "LIMIT",
        "desc": "限价单"
    },
    {
        "value": "MARKET",
        "desc": "市价单"
    },
    {
        "value": "TAKE_PROFIT_MARKET",
        "desc": "止盈"
    },
    {
        "value": "STOP_MARKET",
        "desc": "止损"
    }
]

export default function Trade() {
    useEffect(() => {
        const handleMobileChange = () => {
            if (document.visibilityState === 'visible') {
                window.location.reload();
            }
        }
        const handleVisibleChange = () => {
            if (document.visibilityState === 'visible') {
                setSymbolRealPrice(0.0)
            }
        }
        document.addEventListener("visibilitychange", handleVisibleChange);
        if (/Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)) {
            // 当前设备是移动设备
            document.addEventListener("visibilitychange", handleMobileChange);
        }
        return () => {
            document.removeEventListener("visibilitychange", handleMobileChange)
            document.removeEventListener("visibilitychange", handleVisibleChange)
        }
    }, [])
    const initAccount = accountMap[localStorage.getItem('account')]
    const theme = useTheme();
    const red = theme.palette.error.main
    const green = theme.palette.success.main
    const [ids, setIds] = useState([])
    const [id, setId] = useState([])
    const [account, setAccount] = useState({})
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [transferFrom, setTransferFrom] = useState('MAIN')
    const [transferTo, setTransferTo] = useState('UMFUTURE')
    const [transferAsset, setTransferAsset] = useState('USDT')
    const [transferAmount, setTransferAmount] = useState(0)
    const [open, setOpen] = useState(false)
    const [leverageOpen, setLeverageOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [success, setSuccess] = useState(true)
    const [msg, setMsg] = useState('')
    const [accountDetail, setAccountDetail] = useState({})
    const [tabsValue, setTabsValue] = useState('SWAP');
    const [tableValue, setTableValue] = useState('swapPosition');
    const [orderType, setOrderType] = useState(spotOrderTypeList[0].value)
    // const [orderTypeList, setOrderTypeList] = useState([])
    const [spotSymbolsList, setSpotSymbolsList] = useState([])
    const [swapSymbolsList, setSwapSymbolsList] = useState([])
    const [coinSymbolsList, setCoinSymbolsList] = useState([])
    const [transferList, setTransferList] = useState([])
    const [tradeSymbol, setTradeSymbol] = useState('')
    const [symbolLeverage, setSymbolLeverage] = useState(1)
    const [openOrdersList, setOpenOrderList] = useState([])
    const [allOrders, setAllOrders] = useState([])
    const [leverageConfig, setLeverageConfig] = useState(1)
    const [dualPosition, setDualPosition] = useState(false)
    const [positionSide, setPositionSide] = useState('OPEN')
    const [dualPositionConfig, setDualPositionConfig] = useState(false)
    const [symbolRealPrice, setSymbolRealPrice] = useState(0.0)
    const [stopOpen, setStopOpen] = useState(false)
    const [dualOpen, setDualOpen] = useState(false)
    const [reduceOnly, setReduceOnly] = useState(true)
    const [marketInput, setMarketInput] = useState({
        BUY: '',
        SELL: '',
    })
    const [marketType, setMarketType] = useState({
        BUY: "amount",
        SELL: "amount"
    })
    const [tradeInfo, setTradeInfo] = useState({
        'BUY': {
            price: '',
            quantity: '',
            amount: '',
        },
        'SELL': {
            price: '',
            quantity: '',
            amount: ''
        },
    })
    const [takeStopMarketInfo, setTakeStopMarketInfo] = useState({
        'BUY': {
            price: '',
            quantity: '',
            amount: '',
            workingType: 'CONTRACT_PRICE',
            type: 'amount',
        },
        'SELL': {
            price: '',
            quantity: '',
            amount: '',
            workingType: 'CONTRACT_PRICE',
            type: 'amount',
        }
    })

    const [symbolStopInfo, setSymbolStopInfo] = useState({
        symbol: '',
        'stop': {
            price: '',
            workingType: 'CONTRACT_PRICE',
        },
        'profit': {
            price: '',
            workingType: 'CONTRACT_PRICE',
        }
    })

    const [swapPosition, setSwapPosition] = useState([])
    const [coinPosition, setCoinPosition] = useState([])


    const tradeSymbolName = useMemo(() => {
        if (tradeSymbol === undefined) {
            setTradeSymbol('')
        }
        let newTradeSymbol = ''
        if (typeof tradeSymbol === 'string') {
            newTradeSymbol = tradeSymbol.replace('USDT', '')
            newTradeSymbol = newTradeSymbol.replace(/USD_.+/, '');
        }
        return newTradeSymbol

    }, [tradeSymbol])

    const initForm = () => {
        setTakeStopMarketInfo({
            'BUY': {
                price: '',
                quantity: '',
                amount: '',
                workingType: 'CONTRACT_PRICE',
                type: 'amount',
            },
            'SELL': {
                price: '',
                quantity: '',
                amount: '',
                workingType: 'CONTRACT_PRICE',
                type: 'amount',
            }
        })
        setTradeInfo({
            'BUY': {
                price: '',
                quantity: '',
                amount: ''
            },
            'SELL': {
                price: '',
                quantity: '',
                amount: ''
            },
        });
        // if (tableValue === 'SPOT') {
        //     setMarketType({
        //         BUY: "amount",
        //         SELL: "amount"
        //     })
        // } else {
        //     setMarketType({
        //         BUY: "quantity",
        //         SELL: "quantity"
        //     })
        // }
        setMarketInput({
            BUY: '',
            SELL: '',
        })
    }
    const symbolsList = useMemo(() => {
        switch (tabsValue) {
            case 'SPOT':
                return spotSymbolsList;
            case 'SWAP':
                return swapSymbolsList;
            default:
                return coinSymbolsList;
        }
    }, [tabsValue, spotSymbolsList, swapSymbolsList, coinSymbolsList]);

    const handleTradeSymbolChange = useCallback((value) => {
        console.log('@@@handleTradeSymbolChange', value)
        setTradeSymbol(value)
    }, [])
    const handleTabsChange = useCallback((event, newValue) => {
        setTabsValue(newValue);
        let newSymbol = ''
        switch (newValue) {
            case 'SPOT':
                // eslint-disable-next-line prefer-destructuring
                newSymbol = spotSymbolsList?.[0]
                break
            case 'SWAP':
                // eslint-disable-next-line prefer-destructuring
                newSymbol = swapSymbolsList?.[0]
                break
            default:
                // eslint-disable-next-line prefer-destructuring
                newSymbol = coinSymbolsList?.[0]
                break
        }
        handleTradeSymbolChange(newSymbol);
        // if (newValue === 'SWAP') {
        //     setMarketType({
        //         BUY: "quantity",
        //         SELL: "quantity"
        //     })
        // }
        if (newValue === 'SPOT') {
            setTableValue('openOrder')
            setMarketType({
                BUY: "amount",
                SELL: "amount"
            })
        } else if (newValue === 'SWAP') {
            setTableValue('swapPosition')
            setMarketType({
                BUY: "amount",
                SELL: "amount"
            })
        } else if (newValue === 'COIN') {
            setTableValue('coinPosition')
            setMarketType({
                BUY: "quantity",
                SELL: "quantity"
            })
        }
        setOrderType('LIMIT')
        getDualSidePosition({ accountId: account?.id, account }).then((data) => {
            setDualPosition(data?.data?.dualSidePosition)
            setDualPositionConfig(data?.data?.dualSidePosition)
        })
    }, [coinSymbolsList, handleTradeSymbolChange, spotSymbolsList, swapSymbolsList]);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }
    const handleCloseDualModal = () => {
        setDualOpen(false)
    }

    const handleCloseStopModal = () => {
        setStopOpen(false)
    }
    const handleLeverageChange = (e, newValue) => {
        setLeverageConfig(newValue)
    }
    const handleLeverageSubmit = useCallback(async () => {
        try {
            setLoading(true)
            const res = await setLeverage({ accountId: id, symbol: tradeSymbol, leverage: leverageConfig, productType: tabsValue })
            if (res.code === 2000) {
                // successOperation()
                setMsg('设置杠杆成功')
                setSuccess(true)
                handleCloseLeverageModal()
                getPositionRisk({ accountId: account?.id, symbol: tradeSymbol, productType: tabsValue }).then(({ data }) => {
                    setSymbolLeverage(data[0].leverage)
                }).catch(() => { })
                getUserNowAccount({ accountId: account?.id }).then((account) => {
                    setSwapPosition(account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0))
                })
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }, [account, id, tradeSymbol, leverageConfig, tabsValue])
    useEffect(() => {
        getAccounts().then(({ data }) => {
            setIds(data)
            // const initAccountData = data.find((x) => x.name === initAccount)
            const initAccountData = data.find((x) => x.name === 'T3')  // 暂时写死
            if (initAccountData) {
                setAccount(initAccountData)
                setId(initAccountData?.id)
            } else {
                setAccount(data[0])
                setId(data[0]?.id)
            }
        })
        return () => {
            setAccount({});
            setIds([]);
        };
    }, [])
    useEffect(() => {
        if (tabsValue !== 'COIN') {
            getOpenOrders({ accountId: account?.id, productType: tabsValue }).then(({ data }) => {
                setOpenOrderList(data)
            })
        }
    }, [account?.id, tabsValue])

    useEffect(() => {
        getPositionRisk({ accountId: account?.id, symbol: tradeSymbol, productType: tabsValue }).then(({ data }) => {
            if (data[0].leverage > 3 && !account.isFollowAccount) {
                handleLeverageSubmit()
            } else {
                setSymbolLeverage(data[0].leverage)
            }
        }).catch(() => { })
    }, [account?.id, tradeSymbol, handleLeverageSubmit, account.isFollowAccount, tabsValue])

    useEffect(() => {
        handleFresh()
        return () => {
            setAccountDetail({});
        };
    }, [account])

    const queryDetail = () => currentDetail(account?.id).then(({ data }) => {
        setAccountDetail(data)
        transferMap.spot.availableFunds = data?.spotSymbolToAmountAndPrice?.USDT?.freeAmount || 0
        transferMap.spot.lockedAmount = data?.spotSymbolToAmountAndPrice?.USDT?.lockedAmount || 0
        transferMap.future.availableFunds = data?.swapAvailableBalance
    })
    const renderSpotSymbol = () => {
        if (accountDetail?.spotSymbolToAmountAndPrice) {
            let spotSymbol = Object.keys(accountDetail?.spotSymbolToAmountAndPrice).map((key) => ({ ...accountDetail?.spotSymbolToAmountAndPrice[key], name: key }))
            spotSymbol = spotSymbol.filter((item) => item.amount * item.price > 2).sort((a, b) => (b.amount * b.price - a.amount * a.price))
            return spotSymbol.map((item) => (
                <Grid item xs={12} lg={3} key={item.name} sx={{ my: 1 }}>
                    <Chip label={`${item.name}:${item.amount}个(≈${fCurrency(item.price * item.amount)})`} />
                </Grid>
            ))
        }
        return []
    }
    const handleAccountChange = ({ target: { value: id } }) => {
        setId(id)
        setAccount(ids.find((account) => account.id === id))
    }
    const handleTransferChange = () => {
        const [from, to] = [transferTo, transferFrom]
        setTransferFrom(from)
        setTransferTo(to)
        setTransferAmount(0)
    }
    const handleOpenModal = () => {
        setOpen(true)
        setTransferAmount(0)
    }
    const handleCloseModal = () => {
        setOpen(false);
    }
    const handleCloseLeverageModal = () => {
        setLeverageOpen(false);
    }
    const handleOpenLeverageModal = () => {
        setLeverageOpen(true)
    }

    const successOperation = () => {
        setMsg('交易成功')
        setSuccess(true)
        handleCloseModal()
        setLoadingDetail(true)
        queryDetail().then(() => {
            setLoadingDetail(false)
        });
        getOpenOrders({ accountId: account?.id, productType: tabsValue }).then(({ data }) => {
            setOpenOrderList(data)
        })
        getUserNowAccount({ accountId: account?.id }).then((account) => {
            setSwapPosition(account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0))
        })
        getCoinUserNowAccount({ accountId: account?.id }).then((account) => {
            setCoinPosition(account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0))
        })
    }

    const followFailCheck = (res) => {
        if (res?.data?.failFollowAccountToResult?.length > 0) {
            let msg = `主账号下单成功！！@跟随下单成功账户:${res.data?.successFollowAccount?.join(', ')}@`
            const failMsg = res.data?.failFollowAccountToResult?.map((e) => `跟随下单失败账户:${e?.accountName}@ ${e?.reason}@`)
            msg += failMsg
            setMsg(msg)
            setSuccess(false)
            handleFresh()
        } else {
            successOperation()
            initForm()
        }
    }


    const handleDualSidePositionSubmit = async () => {
        try {
            setLoading(true)
            const res = await setDualSidePosition({ accountId: id, dualSidePosition: dualPositionConfig, productType: tabsValue })
            if (res.code === 2000) {
                setMsg('设置成功')
                setSuccess(true)
                handleCloseDualModal()
                getDualSidePosition({ accountId: account?.id, productType: tabsValue }).then((data) => {
                    setDualPosition(data?.data?.dualSidePosition)
                    setDualPositionConfig(data?.data?.dualSidePosition)
                })
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }

    const handleTransferSubmit = async () => {
        // let type
        // if (transferFrom === 'spot' && transferTo === 'future') {
        //     type = 1
        // } else if (transferFrom === 'future' && transferTo === 'spot') {
        //     type = 2
        // }
        const amount = parseFloat(transferAmount)
        try {
            setLoading(true)
            const res = await transfer({ accountId: id, type: [transferFrom, transferTo].join('_'), asset: transferAsset, amount })
            if (res.code === 2000) {
                successOperation()
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }


    const handleMakeOrder = async (side, type = orderType) => {
        if (!tradeSymbol) {
            setMsg('请选择交易对')
            setSuccess(false)
            setSnackbarOpen(true)
            return
        }
        let info = {}

        if (orderType === 'LIMIT') {
            info = tradeInfo[side]
            console.log('@@@info', info, tradeInfo[side])
            if (tabsValue === 'COIN') {
                info.quoteOrderQty = tradeInfo[side].quantity
            }
        } else if (orderType === 'MARKET') {
            if (marketType[side] === 'amount' && tabsValue === 'SPOT') {
                info = {
                    quoteOrderQty: marketInput[side]
                }
            } else if (marketType[side] === 'quantity' && tabsValue === 'SPOT') {
                info = {
                    quantity: marketInput[side]
                }
            } else if (marketType[side] === 'amount' && (tabsValue === 'SWAP' || tabsValue === 'COIN')) {
                if (!symbolRealPrice || symbolRealPrice <= 0) {
                    setMsg('没有获取到最新币种价格')
                    setSuccess(false)
                    setSnackbarOpen(true)
                    return
                }
                info = {
                    quantity: marketInput[side] / symbolRealPrice
                }
                if (tabsValue === 'COIN') {
                    info = {
                        quoteOrderQty: marketInput[side] / symbolRealPrice
                    }
                }
            } else if (marketType[side] === 'quantity' && (tabsValue === 'SWAP' || tabsValue === 'COIN')) {
                info = {
                    quantity: marketInput[side]
                }
                if (tabsValue === 'COIN') {
                    info = {
                        quoteOrderQty: marketInput[side]
                    }
                }
            }
        } else if (orderType === 'STOP_MARKET' || orderType === 'TAKE_PROFIT_MARKET') {
            if (takeStopMarketInfo[side].type === 'amount') {
                info.quantity = takeStopMarketInfo[side].amount / takeStopMarketInfo[side].price
            } else {
                info.quantity = takeStopMarketInfo[side].quantity
            }
            info.workingType = takeStopMarketInfo[side].workingType
            info.stopPrice = takeStopMarketInfo[side].price
            info.reduceOnly = reduceOnly
        }
        if (dualPosition) {
            info.positionSide = dualTradeMap[positionSide][side].positionSide
        }
        try {
            setLoading(true)
            // 不是止盈止损单，进行校验
            if (orderType !== 'STOP_MARKET' && orderType !== 'TAKE_PROFIT_MARKET') {
                const calculateRes = await calculateOrder({ flatFlag: false, accountId: id, productType: tabsValue, symbol: tradeSymbol, side, type, ...info })
                if (calculateRes.code !== 2000) {
                    setMsg(calculateRes.msg)
                    setSuccess(false)
                    setSnackbarOpen(true)
                    setLoading(false)
                } else {
                    MtdModal.confirm({
                        title: '确认交易',
                        content: <div>
                            <p>{`币种:${calculateRes.data.symbol}`}</p>
                            <p>{`价格:${fCurrency(calculateRes.data.price)}`}</p>
                            <p>{`数量:${fNumber4(tabsValue !== 'COIN' ? calculateRes.data.quantity : calculateRes.data.quoteOrderQty)}`}</p>
                            {tabsValue !== 'COIN' && <p>{`总价:${fCurrency(calculateRes.data.quoteOrderQty)}`}</p>}
                        </div>,
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                            try {
                                if (tabsValue === 'COIN') {
                                    delete info.quoteOrderQty
                                    info.quantity = calculateRes?.data?.quantity
                                }
                                const res = await makeOrder({ flatFlag: false, accountId: id, productType: tabsValue, symbol: tradeSymbol, side, type, ...info })
                                if (res.code === 2000) {
                                    // 如果存在跟单失败的情况
                                    followFailCheck(res)
                                } else {
                                    if (res.data) {
                                        setMsg(res.msg + res.data)
                                    } else if (res.msg) {
                                        setMsg(res.msg)
                                    }
                                    setSuccess(false)
                                }
                            } catch (e) {
                                setMsg(e)
                                setSuccess(false)
                            } finally {
                                setSnackbarOpen(true)
                                setLoading(false)
                            }
                        },
                        onCancel: () => {
                            setMsg('取消下单')
                            setSuccess(false)
                            setSnackbarOpen(true)
                            setLoading(false)
                        }
                    });
                }
            } else { // 止盈止损单直接下单
                try {
                    const res = await makeOrder({ flatFlag: false, accountId: id, productType: tabsValue, symbol: tradeSymbol, side, type, ...info })
                    if (res.code === 2000) {
                        successOperation()
                        initForm()
                    } else {
                        if (res.data) {
                            setMsg(res.msg + res.data)
                        } else if (res.msg) {
                            setMsg(res.msg)
                        }
                        setSuccess(false)
                    }
                } catch (e) {
                    setMsg(e)
                    setSuccess(false)
                } finally {
                    setSnackbarOpen(true)
                    setLoading(false)
                }
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
            setSnackbarOpen(true)
            setLoading(false)
        }
    }



    const handleClosePosition = async ({ symbol, side, orderType = "MARKET", quantity, positionSide, rate = 1 }) => {
        const info = {}
        if (dualPosition) {
            info.positionSide = positionSide
        }
        try {
            setLoading(true)
            const res = await makeOrder({ ...info, accountId: id, productType: tabsValue, symbol, side, type: orderType, quantity: quantity * rate, flatFlag: true })
            if (res.code === 2000) {
                followFailCheck(res)
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }
    const handleCloseAllPosition = async () => {
        try {
            setLoading(true)
            const res = await Promise.all(swapPosition.map(({ symbol, positionAmt, positionSide }) => {
                const side = positionAmt < 0 ? 'BUY' : "SELL";
                const quantity = Math.abs(positionAmt)
                const info = {}
                if (dualPosition) {
                    info.positionSide = positionSide
                }

                return makeOrder({ ...info, accountId: id, productType: tabsValue, symbol, side, type: "MARKET", quantity, flatFlag: true })
            }))
            const errorRes = res.find((item) => item.code !== 2000)
            const followFail = res.find((item) => item?.data?.failFollowAccountToResult?.length > 0)
            if (!errorRes && !followFail) {
                successOperation()
                initForm()
            } else if (followFail) {
                followFailCheck(followFail)
            }
            else {
                setSuccess(false)
                setMsg(errorRes.msg)
            }
        } catch (e) {
            setMsg(e)
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }

    const handleCancelOrder = async ({ orderId, symbol }) => {
        const list = [{
            orderId,
            symbol,
        }]
        try {
            setLoading(true)
            const res = await cancelOrder({ accountId: id, productType: tabsValue, list })
            if (res.code === 2000) {
                successOperation()
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (error) {
            setMsg(error || '网络请求错误')
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }
    const handleFresh = async () => {
        try {
            setLoadingDetail(true)
            Promise.all([
                queryDetail(),
                getUserNowAccount({ accountId: account?.id }).then((account) => {
                    setSwapPosition(account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0))
                }),
                getCoinUserNowAccount({ accountId: account?.id }).then((account) => {
                    setCoinPosition(account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0))
                }),
                getSpotSymbols().then((data) => {
                    setSpotSymbolsList(data)
                }),
                getSwapSymbols().then((data) => {
                    setSwapSymbolsList(data)
                }),
                getCoinSymbols().then((data) => {
                    setCoinSymbolsList(data)
                }),
                getTransferEnum().then((data) => {
                    setTransferList(data)
                }),
                getOpenOrders({ accountId: account?.id, productType: tabsValue }).then(({ data }) => {
                    setOpenOrderList(data)
                }),
                getDualSidePosition({ accountId: account?.id, productType: tabsValue }).then((data) => {
                    setDualPosition(data?.data?.dualSidePosition)
                    setDualPositionConfig(data?.data?.dualSidePosition)
                }),
            ]).then(() => {
                initForm()
                setLoadingDetail(false)
            }).catch((e) => {
                console.error('@@@error', e)
                // setMsg(e)
                // setSuccess(false)
                // setSnackbarOpen(true)
                // setLoadingDetail(false)
            })
        } catch (e) {
            console.error('@@@error', e)
        }
    }

    const handleCancelAllOrder = async () => {
        const list = openOrdersList.map((order) => ({ symbol: order.symbol, orderId: order.orderId }))
        try {
            setLoading(true)
            const res = await cancelOrder({ accountId: id, productType: tabsValue, list })
            if (res.code === 2000) {
                successOperation()
            } else {
                setMsg(res.msg)
                setSuccess(false)
            }
        } catch (error) {
            setMsg(error || '网络请求错误')
            setSuccess(false)
        } finally {
            setSnackbarOpen(true)
            setLoading(false)
        }
    }

    const selectPosition = useMemo(() => {
        const position = swapPosition?.find((position) => position.symbol === tradeSymbol)
        return position
    }, [tradeSymbol, swapPosition])


    const renderBasicInfo = () => (
        <Card>
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction="row" alignItems="center" sx={{ mt: { xs: 2, lg: 5 }, mx: { xs: 2, lg: 5 } }}>
                    <Typography variant="h4" >
                        {`账号:${account?.name}`}
                    </Typography>
                    <Button variant="contained" onClick={handleFresh} sx={{ ml: 2 }}>刷新</Button>
                </Stack>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">切换账号</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={id}
                        onChange={handleAccountChange}
                        label="account"
                    >
                        {
                            ids?.map((account) => (
                                <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Divider />
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Typography variant="h5" paragraph sx={{ my: { xs: 2, lg: 2 }, mx: { xs: 2, lg: 5 } }}>
                    总资产：<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.totalEquity)}`}</span>
                </Typography>
                <Button variant="contained" sx={{ mr: 11 }} onClick={handleOpenModal}>划转</Button>
            </Stack>
            <Divider />

            <Grid container sx={{ my: { xs: 2, lg: 2 }, mx: { xs: 2, lg: 5 } }}>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        合约资产:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.swapMarginBalance)}`}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        可用资产:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.swapAvailableBalance, '$0,0.0')}`}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        合约总仓位:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.totalOpenAmount, '$0,0.0')}`}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        杠杆率:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fNumber2(accountDetail?.totalOpenAmount / (accountDetail?.swapWalletBalance - accountDetail?.swapAvailableBalance))}`}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        持仓保证金:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.swapWalletBalance - accountDetail?.swapAvailableBalance)}`}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant="h5">
                        未实现盈亏:<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(accountDetail?.swapUnrealizedProfit)}`}</span>
                    </Typography>
                </Grid>
            </Grid>
            <Divider />


            <Grid container sx={{ my: { xs: 2 }, mx: { xs: 2, lg: 5 }, alignItems: "center" }}>
                <Grid item xs={12} lg={12} >
                    <Typography variant="h5" >
                        现货资产:
                    </Typography>
                </Grid>
                {
                    renderSpotSymbol()
                }
            </Grid>
        </Card>
    )

    const renderTable = () => (
        <Card sx={{ my: { xs: 2, lg: 5 }, p: { xs: 1, lg: 3 } }}>
            <TabContext value={tableValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => { setTableValue(newValue) }} >
                        {
                            tabsValue === 'COIN' && <Tab label="合约仓位" value="coinPosition" />
                        }
                        {
                            tabsValue === 'SWAP' && <Tab label="合约仓位" value="swapPosition" />
                        }
                        {
                            tabsValue !== 'COIN' && <Tab label="当前委托" value="openOrder" />
                        }
                        {
                            tabsValue === 'SWAP' && <Tab label="交易记录" value="allOrders" />
                        }
                    </TabList>
                </Box>
                <TabPanel value="openOrder" sx={{ p: 0 }}>
                    {
                        renderOpenOrderTable()
                    }
                </TabPanel>
                <TabPanel value="swapPosition" sx={{ p: 0 }}>
                    {
                        renderSwapPositionTable()
                    }
                </TabPanel>
                <TabPanel value="allOrders" sx={{ p: 0 }}>
                    <AllOrders orders={allOrders} accountId={account?.id} />
                </TabPanel>
                <TabPanel value="coinPosition" sx={{ p: 0 }}>
                    {
                        renderCoinPositionTable()
                    }
                </TabPanel>
            </TabContext>
        </Card>
    )

    // 交易功能
    const renderTrade = useCallback(() => (
        <Card sx={{ my: { xs: 2, lg: 5 }, p: { xs: 1, lg: 3 } }}>
            <TabContext value={tabsValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <TabList onChange={handleTabsChange} >
                            <Tab label="合约交易" value="SWAP" />
                            <Tab label="现货交易" value="SPOT" />
                            <Tab label="币本位合约" value="COIN" />
                        </TabList>
                        <Button onClick={() => setDualOpen(true)}>设置仓位模式</Button>
                    </Stack>
                </Box>
                {
                    dualPosition && tabsValue === 'SWAP' &&
                    <TabContext value={positionSide}>
                        <Stack direction="row" justifyContent="space-between">
                            <TabList onChange={(_, newValue) => setPositionSide(newValue)} >
                                <Tab label="开仓" value="OPEN" />
                                <Tab label="平仓" value="CLOSE" />
                            </TabList>
                        </Stack>
                    </TabContext>
                }
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <TabPanel value="SPOT" sx={{ p: 1 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={orderType}
                            exclusive
                            onChange={(event, newType) => {
                                setOrderType(newType);
                                initForm()
                            }}
                            aria-label="Platform"
                        >
                            {
                                spotOrderTypeList.map((orderType) => (
                                    <ToggleButton value={orderType.value} key={orderType.value}>{orderType.desc}</ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
                    </TabPanel>
                    <TabPanel value="SWAP" sx={{ p: 1 }}>
                        <Stack direction="row" alignItems="center">
                            <ToggleButtonGroup
                                color="primary"
                                value={orderType}
                                exclusive
                                onChange={(event, newType) => {
                                    setOrderType(newType);
                                    initForm()
                                }}
                                aria-label="Platform"
                            >
                                {
                                    swapOrderTypeList.map((orderType) => (
                                        <ToggleButton value={orderType.value} key={orderType.value}>{orderType.desc}</ToggleButton>
                                    ))
                                }
                            </ToggleButtonGroup>
                            {
                                (orderType === 'TAKE_PROFIT_MARKET' || orderType === 'STOP_MARKET')
                                &&
                                <>只减仓:< Switch checked={reduceOnly} onChange={(event) => setReduceOnly(event.target.checked)} color="secondary" /></>
                            }
                        </Stack>
                    </TabPanel>
                    <TabPanel value="COIN" sx={{ p: 1 }}>
                        <Stack direction="row" alignItems="center">
                            <ToggleButtonGroup
                                color="primary"
                                value={orderType}
                                exclusive
                                onChange={(event, newType) => {
                                    setOrderType(newType);
                                    initForm()
                                }}
                                aria-label="Platform"
                            >
                                {
                                    coinOrderTypeList.map((orderType) => (
                                        <ToggleButton value={orderType.value} key={orderType.value}>{orderType.desc}</ToggleButton>
                                    ))
                                }
                            </ToggleButtonGroup>
                            {
                                (orderType === 'TAKE_PROFIT_MARKET' || orderType === 'STOP_MARKET')
                                &&
                                <>只减仓:< Switch checked={reduceOnly} onChange={(event) => setReduceOnly(event.target.checked)} color="secondary" /></>
                            }
                        </Stack>
                    </TabPanel>
                    <Stack sx={{ minWidth: '130px' }} lg={{ minWidth: '180px' }}>
                        <ReactSelectMaterialUi
                            value={tradeSymbol}
                            label="交易对"
                            options={symbolsList}
                            onChange={handleTradeSymbolChange}
                            fullWidth={false}
                            variant='filled'
                            // style={{ minWidth: "180px" }}
                            SelectProps={{
                                isClearable: true,
                                styles: {
                                    menuList: (base) => ({
                                        ...base,
                                        backgroundColor: theme.palette.background.default,
                                    }),
                                },
                            }}
                            sx={{ zIndex: 100, color: theme.palette.text.primary, backgroundColor: theme.palette.background.default }}
                        />
                    </Stack>
                </Stack>
                <Grid container justifyContent="center">
                    <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                        {
                            tabsValue !== 'COIN' &&
                            <Typography>
                                可用资产<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${tabsValue === 'SPOT' ? fCurrency(accountDetail?.spotSymbolToAmountAndPrice?.USDT?.amount) : fCurrency(accountDetail?.swapAvailableBalance)}`}</span>
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                        {tradeSymbol && <RealTimePrice symbol={tradeSymbol} productType={tabsValue} callback={setSymbolRealPrice} price={symbolRealPrice} />}
                    </Grid>
                    {
                        tabsValue === 'SWAP' &&
                        <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                            <Typography>
                                当前持仓<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(selectPosition?.openAmount, '$0,0.0')}`}</span>
                            </Typography>
                        </Grid>

                    }
                    {
                        tabsValue === 'SWAP' &&
                        <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                            <Typography>
                                未实现盈亏<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fCurrency(selectPosition?.unrealizedProfit, '$0,0.0')}`}</span>
                            </Typography>
                        </Grid>

                    }
                    {
                        tabsValue === 'SWAP' &&
                        <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                            <Typography>
                                回报率<span style={{ color: 'rgb(112, 122, 138)' }}>{`≈${fPercent(selectPosition?.unrealizedProfit * 100 / Math.abs(selectPosition?.openAmount))}`}</span>
                            </Typography>
                        </Grid>

                    }
                    {

                        (tabsValue === 'SWAP' || tabsValue === 'COIN') &&
                        <Grid item xs={6} lg={2} justifyContent="center" alignContent="center">
                            <Button variant="contained" onClick={handleOpenLeverageModal}>{`${symbolLeverage}x`}</Button>
                        </Grid>
                    }
                </Grid>
                <Grid container>
                    {/* 限价单&市价单 买 */}
                    {(orderType === 'LIMIT' || orderType === 'MARKET') &&
                        <>
                            <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                                <Stack spacing={3}>
                                    {
                                        orderType === 'LIMIT' &&
                                        <TextField
                                            value={tradeInfo.BUY.price}
                                            fullWidth
                                            label="价格"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, BUY: { ...tradeInfo.BUY, amount: value * tradeInfo.BUY.quantity, price: value } })}
                                            disabled={orderType === 'MARKET'}
                                        />
                                    }
                                    {
                                        orderType === 'MARKET' &&
                                        <Stack direction="row">
                                            <FormControl sx={{ width: "120px" }}>
                                                <Select
                                                    value={marketType.BUY}
                                                    onChange={(event) => {
                                                        setMarketType({ ...marketType, BUY: event.target.value });
                                                    }}
                                                >
                                                    <MenuItem value="quantity">数量</MenuItem>
                                                    {tabsValue !== 'COIN' && <MenuItem value="amount">成交额</MenuItem>}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                value={marketInput.BUY}
                                                fullWidth
                                                label={`${marketType.BUY === 'quantity' ? '数量' : '成交额'}`}
                                                id="fullWidth"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">{`${marketType.BUY === 'quantity' ? tradeSymbolName : 'USDT'}`}</InputAdornment>,
                                                }}
                                                onChange={({ target: { value } }) => setMarketInput({ ...marketInput, BUY: value })}
                                            />
                                        </Stack>
                                    }
                                    {
                                        orderType === 'LIMIT' &&
                                        <TextField
                                            value={tradeInfo.BUY.quantity}
                                            fullWidth
                                            label="数量"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{tradeSymbolName}</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, BUY: { ...tradeInfo.BUY, amount: value * tradeInfo.BUY.price, quantity: value } })}
                                        />
                                    }
                                    {
                                        orderType === 'LIMIT' && tabsValue !== 'COIN' &&
                                        <TextField
                                            value={tradeInfo.BUY.amount}
                                            fullWidth
                                            label="成交额"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, BUY: { ...tradeInfo.BUY, amount: value, quantity: value / tradeInfo.BUY.price } })}
                                        />
                                    }
                                    {
                                        (orderType === 'LIMIT' || orderType === 'MARKET') &&
                                        <LoadingButton
                                            onClick={() => handleMakeOrder('BUY')}
                                            loading={loading}
                                            disabled={!symbolRealPrice || symbolRealPrice <= 0}
                                            variant="contained"
                                            color="success"
                                            sx={{ height: 56 }}>
                                            {`${dualPosition ? dualTradeMap[positionSide].BUY.description : '买入'}${tradeSymbolName}`}
                                        </LoadingButton>
                                    }
                                </Stack>
                            </Grid>
                            {/* 限价单&市价单 卖 */}
                            <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                                <Stack spacing={3}>
                                    {
                                        orderType === 'LIMIT' &&
                                        <TextField
                                            value={tradeInfo.SELL.price}
                                            fullWidth
                                            label="价格"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, SELL: { ...tradeInfo.SELL, amount: value * tradeInfo.SELL.quantity, price: value } })}
                                            disabled={orderType === 'MARKET'}
                                        />
                                    }
                                    {
                                        orderType === 'MARKET' &&
                                        <Stack direction="row">
                                            <FormControl sx={{ width: "120px" }}>
                                                <Select
                                                    value={marketType.SELL}
                                                    onChange={(event) => {
                                                        setMarketType({ ...marketType, SELL: event.target.value });
                                                    }}
                                                >
                                                    <MenuItem value="quantity">数量</MenuItem>
                                                    {tabsValue !== 'COIN' && <MenuItem value="amount">成交额</MenuItem>}                                                </Select>
                                            </FormControl>
                                            <TextField
                                                value={marketInput.SELL}
                                                fullWidth
                                                label={`${marketType.SELL === 'quantity' ? '数量' : '成交额'}`}
                                                id="fullWidth"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">{`${marketType.SELL === 'quantity' ? tradeSymbolName : 'USDT'}`}</InputAdornment>,
                                                }}
                                                onChange={({ target: { value } }) => setMarketInput({ ...marketInput, SELL: value })}
                                            />
                                        </Stack>
                                    }
                                    {orderType === 'LIMIT' &&
                                        <TextField
                                            value={tradeInfo.SELL.quantity}
                                            fullWidth
                                            label="数量"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{tradeSymbolName}</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, SELL: { ...tradeInfo.SELL, amount: value * tradeInfo.SELL.price, quantity: value } })}
                                        />
                                    }
                                    {
                                        orderType === 'LIMIT' && tabsValue !== 'COIN' &&
                                        <TextField
                                            value={tradeInfo.SELL.amount}
                                            fullWidth
                                            label="成交额"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => setTradeInfo({ ...tradeInfo, SELL: { ...tradeInfo.SELL, amount: value, quantity: value / tradeInfo.SELL.price } })}
                                            disabled={orderType === 'MARKET'}
                                        />
                                    }
                                    {
                                        (orderType === 'LIMIT' || orderType === 'MARKET') &&
                                        <LoadingButton
                                            onClick={() => handleMakeOrder('SELL')}
                                            loading={loading}
                                            disabled={!symbolRealPrice || symbolRealPrice <= 0}
                                            variant="contained"
                                            color="error"
                                            sx={{ height: 56 }}>
                                            {`${dualPosition ? dualTradeMap[positionSide].SELL.description : '卖出'}${tradeSymbolName}`}
                                        </LoadingButton>
                                    }
                                </Stack>
                            </Grid>
                        </>
                    }

                    {
                        (orderType === 'STOP_MARKET' || orderType === 'TAKE_PROFIT_MARKET') &&
                        ['BUY', "SELL"].map((side) => (
                            <Grid item xs={12} lg={6} sx={{ p: 2 }} key={side}>
                                <Stack spacing={3}>
                                    <Stack direction="row">
                                        <FormControl sx={{ width: "120px" }}>
                                            <Select
                                                value={takeStopMarketInfo[side].workingType}
                                                onChange={(event) => {
                                                    const newObject = { ...takeStopMarketInfo }
                                                    newObject[side] = { ...takeStopMarketInfo[side], workingType: event.target.value }
                                                    setTakeStopMarketInfo(newObject);
                                                }}
                                            >
                                                <MenuItem value="MARK_PRICE">标记</MenuItem>
                                                <MenuItem value="CONTRACT_PRICE">最新</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            value={takeStopMarketInfo[side].price}
                                            fullWidth
                                            label="触发价格"
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => {
                                                const newObject = { ...takeStopMarketInfo }
                                                newObject[side] = { ...takeStopMarketInfo[side], price: value }
                                                setTakeStopMarketInfo(newObject)
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row">
                                        <FormControl sx={{ width: "120px" }}>
                                            <Select
                                                value={takeStopMarketInfo[side].type}
                                                onChange={({ target: { value } }) => {
                                                    const newObject = { ...takeStopMarketInfo }
                                                    newObject[side] = { ...takeStopMarketInfo[side], type: value }
                                                    setTakeStopMarketInfo(newObject);
                                                }}
                                            >
                                                <MenuItem value="quantity">数量</MenuItem>
                                                <MenuItem value="amount">成交额</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            value={`${takeStopMarketInfo[side][takeStopMarketInfo[side].type]}`}
                                            fullWidth
                                            label={`${takeStopMarketInfo[side].type === 'quantity' ? '数量' : '成交额'}`}
                                            id="fullWidth"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{`${takeStopMarketInfo[side].type === 'quantity' ? tradeSymbolName : 'USDT'}`}</InputAdornment>,
                                            }}
                                            onChange={({ target: { value } }) => {
                                                const newObject = { ...takeStopMarketInfo }
                                                newObject[side][takeStopMarketInfo[side].type] = value
                                                setMarketInput(newObject)
                                            }}
                                        />
                                    </Stack>
                                    <LoadingButton
                                        onClick={() => handleMakeOrder(side)}
                                        loading={loading}
                                        variant="contained"
                                        disabled={!symbolRealPrice || symbolRealPrice <= 0}
                                        color={`${side === 'BUY' ? 'success' : 'error'}`}
                                        sx={{ height: 56 }}>
                                        {`${side === 'BUY' ? ' 买入/做多' : '卖出/做空'}`}
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
                {/* <TabPanel value={2}>Item Two</TabPanel> */}
            </TabContext>
        </Card >
    ), [accountDetail?.spotSymbolToAmountAndPrice?.USDT?.amount, accountDetail?.swapAvailableBalance, dualPosition, handleMakeOrder, handleTabsChange, loading, marketInput, marketType, orderType, positionSide, reduceOnly, selectPosition?.openAmount, selectPosition?.unrealizedProfit, symbolLeverage, symbolRealPrice, symbolsList, tabsValue, takeStopMarketInfo, theme.palette.background.default, theme.palette.text.primary, tradeInfo, tradeSymbol, tradeSymbolName])

    const renderOpenOrderTable = () => (
        <Container style={{ maxWidth: '1600px' }}>
            <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                    loading={loading}
                    onClick={handleCancelAllOrder}
                    disabled={!openOrdersList?.length}
                >全部撤销
                </LoadingButton>
            </Stack>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <OrderListHead
                                headLabel={TABLE_HEAD}
                                rowCount={openOrdersList?.length}
                                disabled
                            />
                            <TableBody>
                                {openOrdersList?.map((row) => {
                                    const { time, symbol, side, price, origQty, cummulativeQuoteQty, orderId, origType, closePosition, type, stopPrice } = row;
                                    const displayQty = closePosition ? 'All' : origQty
                                    const displayPrice = (type === 'STOP_MARKET' || type === 'TAKE_PROFIT_MARKET') ? fCurrency(stopPrice) : fCurrency(price)
                                    const _price = (type === 'STOP_MARKET' || type === 'TAKE_PROFIT_MARKET') ? stopPrice : price
                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                        >

                                            <TableCell align="center">{symbol}</TableCell>
                                            <TableCell align="center">{orderTypeMap[origType || type]}</TableCell>
                                            <TableCell align="center" style={{ color: `${side === 'BUY' ? green : red}` }}>{side === 'BUY' ? '买' : '卖'}</TableCell>
                                            <TableCell align="center" >{displayPrice}</TableCell>
                                            <TableCell align="center">{fCurrency(_price * origQty)}</TableCell>
                                            {/* <TableCell align="center" >{displayQty}</TableCell> */}
                                            <TableCell align="center">{fCurrency(cummulativeQuoteQty)}</TableCell>
                                            <TableCell align="center">{fPercent(cummulativeQuoteQty / (_price * origQty))}</TableCell>
                                            <TableCell align="center">{fCDateTime(time)}</TableCell>
                                            <TableCell align="center">
                                                <LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleCancelOrder({ symbol, orderId })}
                                                >撤销
                                                </LoadingButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </Container>
    )

    const renderSwapPositionTable = () => (
        <Container style={{ maxWidth: '1600px' }}>
            <Stack direction="row" justifyContent="flex-end">
                {/* <Typography variant="h5" gutterBottom>
                    合约仓位
                </Typography> */}
                <LoadingButton
                    loading={loading}
                    onClick={handleCloseAllPosition}
                    disabled={!swapPosition?.length}
                >一键平仓
                </LoadingButton>
            </Stack>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <OrderListHead
                                headLabel={SWAP_POSITION_TABLE_HEAD}
                                rowCount={swapPosition?.length}
                                disabled
                            />
                            <TableBody>
                                {swapPosition?.map((row) => {
                                    const { symbol, positionInitialMargin, notional, positionAmt, leverage, unrealizedProfit, entryPrice, positionSide, openAmount } = row;
                                    // 与持仓方向相反
                                    const tradeSide = positionAmt < 0 ? 'BUY' : "SELL";
                                    return (
                                        <TableRow
                                            hover
                                            key={symbol}
                                            tabIndex={-1}
                                            role="checkbox"
                                        >

                                            <TableCell align="center">
                                                <Typography variant="subtitle2" noWrap>
                                                    {symbol}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" style={{ color: `${positionAmt > 0 ? red : green}` }}>{fCurrency(openAmount, '$0,0.0')}</TableCell>
                                            {/* <TableCell align="center" style={{ color: `${positionAmt > 0 ? red : green}` }}>{fCurrency(Math.abs(notional), '$0,0.0')}</TableCell> */}
                                            {/* <TableCell align="center" >{positionAmt}</TableCell> */}
                                            <TableCell align="center" style={{ color: `${positionAmt > 0 ? red : green}` }}>{`${positionAmt < 0 ? '卖出' : '买入'}`}</TableCell>
                                            <TableCell align="center" style={{ color: `${unrealizedProfit > 0 ? red : green}` }}>{`${fCurrency(unrealizedProfit, '$0,0.00')}`}</TableCell>
                                            <TableCell align="center" style={{ color: `${unrealizedProfit > 0 ? red : green}` }}>{`${fPercent(unrealizedProfit * 100 / Math.abs(openAmount))}`}</TableCell>
                                            <TableCell align="center">{fCurrency(positionInitialMargin)}</TableCell>
                                            <TableCell align="center">{fCurrency(entryPrice)}</TableCell>
                                            <TableCell align="center">{leverage}</TableCell>
                                            <TableCell align="center">
                                                <LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 0.3 })}
                                                >30%
                                                </LoadingButton>
                                                <LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 0.5 })}
                                                >50%
                                                </LoadingButton><LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 1 })}
                                                >100%
                                                </LoadingButton>
                                                {/* <LoadingButton
                                                    onClick={() => {
                                                        setStopOpen(true)
                                                        setSymbolStopInfo({ ...symbolStopInfo, symbol })
                                                    }}
                                                >止盈止损
                                                </LoadingButton> */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </Container>
    )

    const renderCoinPositionTable = () => (
        <Container style={{ maxWidth: '1600px' }}>
            {/* <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                    loading={loading}
                    onClick={handleCloseAllPosition}
                    disabled={!swapPosition?.length}
                >一键平仓
                </LoadingButton>
            </Stack> */}
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <OrderListHead
                                headLabel={COIN_POSITION_TABLE_HEAD}
                                rowCount={coinPosition?.length}
                                disabled
                            />
                            <TableBody>
                                {coinPosition?.map((row) => {
                                    const { symbol, positionInitialMargin, positionAmtInCoin, notional, positionAmt, leverage, unrealizedProfit, entryPrice, positionSide, openAmount } = row;
                                    // 与持仓方向相反
                                    const tradeSide = positionAmt < 0 ? 'BUY' : "SELL";
                                    return (
                                        <TableRow
                                            hover
                                            key={symbol}
                                            tabIndex={-1}
                                            role="checkbox"
                                        >

                                            <TableCell align="center">
                                                <Typography variant="subtitle2" noWrap>
                                                    {symbol}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" style={{ color: `${positionAmtInCoin > 0 ? red : green}` }}>{fCurrency(positionAmtInCoin, '$0,0.000')}</TableCell>
                                            {/* <TableCell align="center" style={{ color: `${positionAmt > 0 ? red : green}` }}>{fCurrency(Math.abs(notional), '$0,0.0')}</TableCell> */}
                                            {/* <TableCell align="center" >{positionAmt}</TableCell> */}
                                            <TableCell align="center" style={{ color: `${positionAmtInCoin > 0 ? red : green}` }}>{`${positionAmtInCoin < 0 ? '卖出' : '买入'}`}</TableCell>
                                            {/* <TableCell align="center" style={{ color: `${unrealizedProfit > 0 ? red : green}` }}>{`${fCurrency(unrealizedProfit, '$0,0.00')}`}</TableCell>
                                            <TableCell align="center" style={{ color: `${unrealizedProfit > 0 ? red : green}` }}>{`${fPercent(unrealizedProfit * 100 / Math.abs(openAmount))}`}</TableCell>
                                            <TableCell align="center">{fCurrency(positionInitialMargin)}</TableCell>
                                            <TableCell align="center">{fCurrency(entryPrice)}</TableCell> */}
                                            <TableCell align="center">{leverage}</TableCell>
                                            {/* <TableCell align="center">
                                                <LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 0.3 })}
                                                >30%
                                                </LoadingButton>
                                                <LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 0.5 })}
                                                >50%
                                                </LoadingButton><LoadingButton
                                                    loading={loading}
                                                    onClick={() => handleClosePosition({ symbol, quantity: Math.abs(positionAmt), side: tradeSide, positionSide, rate: 1 })}
                                                >100%
                                                </LoadingButton>
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </Container>
    )


    return (
        <RootStyle title="交易">
            <Container sx={{ pt: { xs: 1, lg: 3 } }} style={{ maxWidth: '1600px' }}>
                {
                    loadingDetail ? <CircularProgress /> : renderBasicInfo()
                }
                {
                    !loadingDetail && renderTrade()
                }
                {
                    !loadingDetail && renderTable()
                }
            </Container>
            {/* 设置杠杆 */}
            <Modal
                open={leverageOpen}
                onClose={handleCloseLeverageModal}
            >
                <Card sx={style}>
                    <CardHeader
                        title="调整杠杆"
                    />
                    <Stack sx={{ m: 3 }}>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={leverageConfig}
                            getAriaValueText={(value) => `${value}x`}
                            valueLabelDisplay="auto"
                            step={1}
                            sx={{ my: 2 }}
                            marks={[
                                {
                                    value: 1,
                                    label: '1x',
                                },
                                {
                                    value: 2,
                                    label: '2x',
                                }, {
                                    value: 3,
                                    label: '3x',
                                },
                            ]}
                            min={1}
                            max={3}
                            onChange={handleLeverageChange}
                        />
                    </Stack>
                    <Stack justifyContent="flex-end" direction="row">
                        <LoadingButton loading={loading} variant="contained" onClick={handleLeverageSubmit}>确定</LoadingButton>
                    </Stack>
                </Card>
            </Modal>

            {/* 设置仓位模式 */}
            <Modal
                open={dualOpen}
                onClose={handleCloseDualModal}
            >
                <Card sx={style}>
                    <CardHeader
                        title="设置仓位模式"
                    />
                    <FormControl sx={{ m: 3 }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={dualPositionConfig}
                            onChange={({ target: { value } }) => {
                                setDualPositionConfig(value)
                            }}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="单向持仓" />
                            <FormControlLabel value control={<Radio />} label="双向持仓" />
                        </RadioGroup>
                    </FormControl>
                    <Stack justifyContent="flex-end" direction="row">
                        <LoadingButton loading={loading} variant="contained" onClick={handleDualSidePositionSubmit}>确定</LoadingButton>
                    </Stack>
                </Card>
            </Modal>

            {/* 划转弹框 */}
            <Modal
                open={open}
                onClose={handleCloseModal}

            >
                <Card sx={style}  >
                    <CardHeader
                        title="划转"
                    />
                    <Card sx={{ m: 3, mb: 5 }}>
                        <Stack direction="row" spacing={12} sx={{ m: 2 }} justifyContent="center" alignItems="center">
                            <Typography sx={{ ml: 8, color: 'rgb(112, 122, 138)' }}>从</Typography>
                            {/* <Typography >{transferMap[transferFrom].name}</Typography> */}
                            <FormControl sx={{ width: "120px" }}>
                                <Select
                                    value={transferFrom}
                                    onChange={(event) => {
                                        setTransferFrom(event.target.value);
                                    }}
                                >
                                    {
                                        Object.keys(transferTypeMap).map((type) => (
                                            <MenuItem value={type} key={type}>{transferValueNameMap[type]}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                        {/* <Stack direction="row" spacing={12} justifyContent="center" sx={{ cursor: 'pointer' }}>
                            <Icon icon={flipFill} width={25} height={25} onClick={handleTransferChange} />
                        </Stack> */}
                        <Stack direction="row" spacing={12} sx={{ m: 2 }} justifyContent="center" alignItems="center">
                            <Typography sx={{ ml: 8, color: 'rgb(112, 122, 138)' }} > 到</Typography>
                            {/* <Typography >{transferMap[transferTo].name}</Typography> */}
                            <FormControl sx={{ width: "120px" }}>
                                <Select
                                    value={transferTo}
                                    onChange={(event) => {
                                        setTransferTo(event.target.value);
                                    }}
                                >
                                    {
                                        transferTypeMap[transferFrom].map((type) => (
                                            <MenuItem value={type} key={type}>{transferValueNameMap[type]}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    </Card>
                    {/* <TextField fullWidth label="币种(不可更改)" id="fullWidth" defaultValue="USDT" disabled /> */}
                    <FormControl fullWidth>
                        <InputLabel id="transferAsset">币种</InputLabel>
                        <Select
                            labelId="transferAsset"
                            id="transferAsset"
                            value={transferAsset}
                            onChange={(event) => {
                                setTransferAsset(event.target.value);
                            }}
                        >
                            <MenuItem value="USDT" >USDT</MenuItem>
                            <MenuItem value="BTC" >BTC</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack justifyContent="flex-end" direction="row" sx={{ mt: 3, mb: 3 }}>
                        <TextField fullWidth label="数量" id="fullWidth" value={transferAmount} onChange={({ target: { value } }) => setTransferAmount(value)} />
                    </Stack>
                    {/* <Stack justifyContent="flex-end" direction="row" sx={{ mt: 3 }}>
                        <Typography >${transferMap[transferFrom].availableFunds}<span style={{ color: 'rgb(112, 122, 138)' }}>可用资产</span> / ${transferMap[transferFrom].lockedAmount}<span style={{ color: 'rgb(112, 122, 138)' }}>下单冻结</span></Typography>
                    </Stack>
                    <TextField fullWidth label="数量" id="fullWidth" value={transferAmount} onChange={({ target: { value } }) => setTransferAmount(value)} />
                    <Fab variant="extended" size="medium" sx={{ position: "relative", top: '-50px', left: '300px' }} onClick={() => setTransferAmount(transferMap[transferFrom].availableFunds)}>
                        全部
                    </Fab> */}
                    <Stack justifyContent="flex-end" direction="row">
                        <LoadingButton loading={loading} variant="contained" onClick={handleTransferSubmit}>确定</LoadingButton>

                    </Stack>
                </Card>
            </Modal>
            {/* 提示框 */}
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ top: "50px" }}
                autoHideDuration={3000} onClose={handleSnackbarClose}
            >

                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%' }}>
                    {
                        typeof msg === 'string' ? msg?.split('@').map((m) => <p>{m}</p>) : msg
                    }
                </Alert>
            </Snackbar>

            {/* 止盈止损 */}
            <Modal
                open={stopOpen}
                onClose={handleCloseStopModal}
            >
                <Card sx={style}>
                    <CardHeader
                        title="仓位止盈止损"
                    />
                    <Stack direction="row">
                        <FormControl sx={{ width: "120px" }}>
                            <Select
                                value={symbolStopInfo.stop}
                                onChange={(event) => {
                                    const newObject = { ...takeStopMarketInfo }
                                    // newObject[side] = { ...takeStopMarketInfo[side], workingType: event.target.value }
                                    setTakeStopMarketInfo(newObject);
                                }}
                            >
                                <MenuItem value="MARK_PRICE">标记</MenuItem>
                                <MenuItem value="CONTRACT_PRICE">最新</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            value=""
                            fullWidth
                            label="触发价格"
                            id="fullWidth"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                            }}
                            onChange={({ target: { value } }) => {
                                const newObject = { ...takeStopMarketInfo }
                                // newObject[side] = { ...takeStopMarketInfo[side], price: value }
                                setTakeStopMarketInfo(newObject)
                            }}
                        />
                    </Stack>

                </Card>
            </Modal>
        </RootStyle >
    );
}

