
import { flatten } from 'lodash';
// import { format } from 'date-fns';
// import ReactApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';

// material
import { Grid, Container, Card } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { accountIdAtom, incomesAtom, accountAtom, tradesAtom } from '../recoil/atoms';
import { getSymbolIncomes } from '../services/incomeServices'
// components
import Page from '../components/Page';
import SymbolLine from '../components/charts/SymbolLine';
import SymbolTradesAnalysis from '../components/charts/SymbolTradesAnalysis';

const SymbolIncome = () => {
  const { state: { orderedPerformers, interval } } = useLocation();
  const [renderData, setRenderData] = useState([]);
  const [intervalDay, setInterval] = useState(interval);
  // const incomes = useRecoilValue(incomesAtom);
  const trades = useRecoilValue(tradesAtom);
  const accountId = useRecoilValue(accountIdAtom)

  const symbolClassify = {};
  flatten(Object.values(trades))?.filter((inc) => inc.realizedPnl !== 0).forEach((inc) => {
    if (symbolClassify[inc?.symbol]) {
      symbolClassify[inc?.symbol].push(inc)
    } else {
      symbolClassify[inc?.symbol] = [inc]
    }
  });
  useEffect(() => {
    const getAllSymbolIncome = orderedPerformers.map((symbolInfo, index) => getSymbolIncomes({ accountId, asset: 'USDT', symbol: symbolInfo.label }).then(({ data }) => ({ data, symbol: symbolInfo.label, sort: index })))
    Promise.all(getAllSymbolIncome).then((data) => {
      data.sort((data1, data2) => data1.sort - data2.sort)
      const render = data.map((item) => (
        <Grid item xs={12} md={6} lg={6} key={item.symbol}>
          <Card>
            <SymbolLine
              incomeData={item.data}
              symbol={item.symbol}
              interval={intervalDay}
              onChange={(interval) => setInterval(interval)}
            />
            <SymbolTradesAnalysis
              interval={intervalDay}
              symbolTrades={symbolClassify[item.symbol] || []}
            />
          </Card>
        </Grid>
      ))
      setRenderData(render)
    })
  }, [intervalDay, accountId])


  return (
    <Page title="币种盈亏">
      <Container style={{ marginTop: 20 }} maxWidth="xl">
        <Grid container spacing={3} >
          {
            renderData
          }
        </Grid>
      </Container>
    </Page>
  );
}

export default SymbolIncome