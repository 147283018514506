import { filter, orderBy, flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Box,
    Tab,
    Tabs,
    Button,
    CircularProgress,
    Fade,
    Snackbar,
    Alert,
    ButtonGroup,
    TextField
} from '@mui/material';
// components
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs'
import data from '@iconify/icons-eva/menu-2-fill';
import {
    CustomerTotal
} from '../components/_dashboard/app';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { OrderListHead, OrderListToolbar } from '../components/_dashboard/order';
import { getCustomerDetail, getCustomers, getCustomerTotal } from '../services/accountServices'
import { incomeHistory } from '../services/incomeServices'
//

import { fCTime } from '../utils/formatTime';
import { fCurrency, fPercent } from '../utils/formatNumber';
import './Overview.css';

// import { tradesAtom } from '../recoil/atoms';

const TABLE_HEAD = [
    { id: 'time', label: '时间' },
    { id: 'totalBaseAmount', label: '本金' },
    { id: 'currentAmount', label: '当前资金' },
    { id: 'currentProfit', label: '当前盈利' },
    { id: 'currentGapProfit', label: '当天盈利' },
    { id: 'currentGapProfitSubtractLastGapProfit', label: '较昨天盈利' },
    { id: 'profitRate', label: '盈利率' },
];

// ---------------------------------------------------------------------

function descendingComparator(a, b, orderedBy) {
    if (b[orderedBy] < a[orderedBy]) {
        return -1;
    }
    if (b[orderedBy] > a[orderedBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderedBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderedBy)
        : (a, b) => -descendingComparator(a, b, orderedBy);
}

function applySortFilter(array, comparator, query, unrealizePNL = false) {
    let stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (!unrealizePNL) {
        stabilizedThis = filter(stabilizedThis, (_order) => parseFloat(_order[0].realizedPnl).toFixed(5) !== '0.00000')
    }
    if (query) {
        return filter(
            stabilizedThis,
            (_order) => _order[0].symbol.toLowerCase().indexOf(query.toLowerCase()) !== -1
        ).map((el) => el[0]);
    }
    return stabilizedThis.map((el) => el[0]);
}

const IncomeHistory = () => {

    const theme = useTheme();
    const red = theme.palette.error.main
    const green = theme.palette.success.main
    const [detail, setDetail] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderedBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const orderedTrades = orderBy(flatten(Object.values(detail)), ['time'], ['desc']);

    useEffect(() => {
        getCustomers().then(({ data }) => {
            setCustomers(data)
            setCustomerId(data[0].id)
        })

    }, [])

    useEffect(() => {
        setDetail([])
        setLoading(true)
        if (customerId) {
            incomeHistory({ customerId }).then(({ data }) => {
                setLoading(false)
                setDetail(data)
            })
        }
    }, [customerId])


    const handleChange = (event, newValue) => {
        setCustomerId(newValue);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderedBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orderedTrades.map((n) => n.symbol);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderedTrades.length) : 0;

    const filteredOrders = applySortFilter(
        orderedTrades,
        getComparator(order, orderedBy),
        filterName
    );

    const isOrderNotFound = filteredOrders.length === 0;

    return (
        <Page title="历史收益记录">
            <Container style={{ maxWidth: '1600px' }}>
                <Box sx={{ mb: 3 }}>
                    <Tabs value={customerId} onChange={handleChange} scrollButtons variant="scrollable">
                        {
                            customers.map((item) => (<Tab style={{ fontSize: '20px' }} label={item.name} key={item.id} value={item.id} />))
                        }
                    </Tabs>
                </Box>
                {/* <Card sx={{ p: 3 }}> */}

                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        实盘清单
                    </Typography>
                </Stack> */}
                {loading ? <CircularProgress sx={{ my: 5, mx: 5 }} /> :

                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <OrderListHead
                                        order={order}
                                        orderBy={orderedBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={detail.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredOrders
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const { time, totalBaseAmount, currentAmount, currentProfit, currentGapProfit, currentGapProfitSubtractLastGapProfit, profitRate } = row;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={time}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                    >
                                                        <TableCell align="center" style={{ minWidth: '100px' }}>{fCTime(time)}</TableCell>
                                                        <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(totalBaseAmount)}</TableCell>
                                                        <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(currentAmount)}</TableCell>
                                                        <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(currentProfit)}</TableCell>
                                                        <TableCell align="center" style={{ color: `${currentGapProfit > 0 ? red : green}`, minWidth: '150px' }}>{fCurrency(currentGapProfit)}</TableCell>
                                                        <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(currentGapProfitSubtractLastGapProfit)}</TableCell>
                                                        <TableCell align="center" style={{ color: `${profitRate > 0 ? red : green}`, minWidth: '150px' }}>{profitRate === null ? '-' : fPercent(profitRate * 100, '0.000%')}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody >
                                    {isOrderNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                    <Typography gutterBottom align="center" variant="subtitle1">
                                                        暂无数据
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table >
                            </TableContainer >
                        </Scrollbar >

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100, 1000]}
                            component="div"
                            count={filteredOrders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card >
                }
                {/* </Card > */}
            </Container >
        </Page >
    );
};

export default IncomeHistory;
