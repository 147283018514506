import { filter, orderBy, flatten } from 'lodash';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

// material
import {
  Card,
  Table,
  Stack,
  // Avatar,
  // Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { OrderListHead, OrderListToolbar } from '../components/_dashboard/order';
//

import { fCDateTime } from '../utils/formatTime';
import { fCurrency } from '../utils/formatNumber';
import { tradesAtom } from '../recoil/atoms';

// ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'symbol', label: 'Symbol' },
//   { id: 'side', label: 'Side' },
//   { id: 'time', label: 'Time' },
//   { id: 'pnl', label: 'Realized PnL' },
//   { id: 'price', label: 'Price' },
//   { id: 'quoteQty', label: 'Quote quantity' },
//   { id: 'commission', label: 'Commission' },
// ];
const TABLE_HEAD = [
  { id: 'symbol', label: '交易对' },
  { id: 'side', label: '多/空方向' },
  { id: 'time', label: '时间' },
  { id: 'pnl', label: '实现盈亏' },
  { id: 'price', label: '成交价' },
  { id: 'quoteQty', label: '成交额' },
  { id: 'commission', label: '手续费' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderedBy) {
  if (b[orderedBy] < a[orderedBy]) {
    return -1;
  }
  if (b[orderedBy] > a[orderedBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderedBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderedBy)
    : (a, b) => -descendingComparator(a, b, orderedBy);
}

function applySortFilter(array, comparator, query, unrealizePNL = false) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (!unrealizePNL) {
    stabilizedThis = filter(stabilizedThis, (_order) => parseFloat(_order[0].realizedPnl).toFixed(5) !== '0.00000')
  }
  if (query) {
    return filter(
      stabilizedThis,
      (_order) => _order[0].symbol.toLowerCase().indexOf(query.toLowerCase()) !== -1
    ).map((el) => el[0]);
  }
  return stabilizedThis.map((el) => el[0]);
}

// {
//   "buyer": false, // 是否是买方
//   "commission": "-0.07819010", // 手续费
//   "commissionAsset": "USDT", // 手续费计价单位
//   "id": 698759,   // 交易ID
//   "maker": false, // 是否是挂单方
//   "orderId": 25851813, // 订单编号
//   "price": "7819.01", // 成交价
//   "qty": "0.002", // 成交量
//   "quoteQty": "15.63802", // 成交额
//   "realizedPnl": "-0.91539999",   // 实现盈亏
//   "side": "SELL", // 买卖方向
//   "positionSide": "SHORT",  // 持仓方向
//   "symbol": "BTCUSDT", // 交易对
//   "time": 1569514978020 // 时间
// }
const OrderHistory = () => {
  const trades = useRecoilValue(tradesAtom);
  console.log('@@@trades', trades)

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderedBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const orderedTrades = orderBy(flatten(Object.values(trades)), ['time'], ['desc']);

  const handleRequestSort = (event, property) => {
    const isAsc = orderedBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderedTrades.map((n) => n.symbol);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderedTrades.length) : 0;

  const filteredOrders = applySortFilter(
    orderedTrades,
    getComparator(order, orderedBy),
    filterName
  );

  const isOrderNotFound = filteredOrders.length === 0;

  return (
    <Page title="Order History | Futures">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order history
          </Typography>
        </Stack>

        <Card>
          <OrderListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderListHead
                  order={order}
                  orderBy={orderedBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderedTrades.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, symbol, buyer, realizedPnl, time, quoteQty, price, commission } = row;
                      const isItemSelected = selected.indexOf(symbol) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="center">
                            <Typography variant="subtitle2" noWrap>
                              {symbol}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Label variant="ghost" color={buyer ? 'info' : 'success'}>
                              {buyer ? '买多' : '买空'}
                            </Label>
                          </TableCell>
                          <TableCell align="center">{fCDateTime(time)}</TableCell>
                          <TableCell align="center">{fCurrency(realizedPnl)}</TableCell>
                          <TableCell align="center">{price}</TableCell>
                          <TableCell align="center">{quoteQty}</TableCell>
                          <TableCell align="center">{commission}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isOrderNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          暂无数据
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 100, 1000]}
            component="div"
            count={filteredOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default OrderHistory;
