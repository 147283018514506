import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import { useRecoilValue } from 'recoil';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { themeAtom } from '../recoil/atoms'

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};


export default function ThemeConfig({ children }) {

  const themeMode = useRecoilValue(themeAtom)
  const themeOptions = {
    palette: palette[themeMode],
    shape,
    typography,
    shadows,
    customShadows: customShadows(themeMode)
  }
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
