import { filter, orderBy, flatten } from 'lodash';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

// material
import {
    Card,
    Table,
    Grid,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CardHeader,
    Container,
    Stack
} from '@mui/material';
// components
import Scrollbar from '../../Scrollbar';
import { OrderListHead } from '../order';
//

import { fCurrency, fPercent } from '../../../utils/formatNumber';
import { accountAtom } from '../../../recoil/atoms';

const TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    { id: 'notional', label: '持仓大小' },
    { id: 'sidePositionRatio', label: '（多/空）持仓比例' },
    { id: 'totalPositionRatio', label: '总持仓比例' },
    { id: 'positionInitialMargin', label: '保证金' },
    { id: 'leverage', label: '杠杆' },
];

// {
//     "symbol": "BTCUSDT",  // 交易对
//     "initialMargin": "0",   // 当前所需起始保证金(基于最新标记价格)
//     "maintMargin": "0", //维持保证金
//     "unrealizedProfit": "0.00000000",  // 持仓未实现盈亏
//     "positionInitialMargin": "0",  // 持仓所需起始保证金(基于最新标记价格)
//     "openOrderInitialMargin": "0",  // 当前挂单所需起始保证金(基于最新标记价格)
//     "leverage": "100",  // 杠杆倍率
//     "isolated": true,  // 是否是逐仓模式
//     "entryPrice": "0.00000",  // 持仓成本价
//     "maxNotional": "250000",  // 当前杠杆下用户可用的最大名义价值
//     "bidNotional": "0",  // 买单净值，忽略
//     "askNotional": "0",  // 卖单净值，忽略
//     "positionSide": "BOTH",  // 持仓方向
//     "positionAmt": "0",      // 持仓数量
//     "updateTime": 0         // 更新时间 
// }
const Positions = () => {

    const account = useRecoilValue(accountAtom);
    const total = account?.positions?.filter((pos) => pos.positionAmt > 0 || pos.positionAmt < 0).map(item => Math.abs(item.notional)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const buyTotal = account?.positions?.filter((pos) => pos.positionAmt > 0).map(item => Math.abs(item.notional)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const sellTotal = account?.positions?.filter((pos) => pos.positionAmt < 0).map(item => Math.abs(item.notional)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const buyPositions = account?.positions?.filter((pos) => pos.positionAmt > 0).map(pos => ({
        ...pos,
        totalPositionRatio: Math.abs(pos.notional / total) * 100,
        sidePositionRatio: Math.abs(pos.notional / buyTotal) * 100,
    })).sort((a, b) => b.notional - a.notional) || [];
    const sellPositions = account?.positions?.filter((pos) => pos.positionAmt < 0).map(pos => ({
        ...pos,
        totalPositionRatio: Math.abs(pos.notional / total) * 100,
        sidePositionRatio: Math.abs(pos.notional / sellTotal) * 100,
    })).sort((a, b) => Math.abs(b.notional) - Math.abs(a.notional)) || [];


    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderedBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(8);


    const handleRequestSort = (event, property) => {
        const isAsc = orderedBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Card>
            <CardHeader
                title="持仓情况"
                sx={{ mb: 1 }}
                subheader={`总持仓为${fCurrency(total)}(买多持仓${fCurrency(buyTotal)}、买空持仓${fCurrency(sellTotal)})`}
            />
            <Typography paragraph sx={{ ml: 3 }}> {`多仓占比:${fPercent(buyTotal * 100 / total)}, 空仓占比${fPercent(sellTotal * 100 / total)}`}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>

                    <Card sx={{ m: 1 }}>
                        <CardHeader
                            title="买多持仓情况"
                        />
                        <Scrollbar>
                            <TableContainer >
                                <Table>
                                    <OrderListHead
                                        order={order}
                                        orderBy={orderedBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={buyPositions?.length || 0}
                                        numSelected={selected?.length || 0}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {buyPositions
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const { id, symbol, positionInitialMargin, notional, totalPositionRatio, leverage, sidePositionRatio } = row;
                                                const isItemSelected = selected.indexOf(symbol) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                    >
                                                        <TableCell align="center">
                                                            <Typography variant="subtitle2" noWrap>
                                                                {symbol}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">{fCurrency(notional)}</TableCell>
                                                        <TableCell align="center">{`${sidePositionRatio.toFixed(2)}%`}</TableCell>
                                                        <TableCell align="center">{`${totalPositionRatio.toFixed(2)}%`}</TableCell>
                                                        <TableCell align="center">{fCurrency(positionInitialMargin)}</TableCell>
                                                        <TableCell align="center">{leverage}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[8]}
                            component="div"
                            count={buyPositions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>

                    <Card sx={{ m: 1 }}>
                        <CardHeader
                            title="买空持仓情况"
                        />
                        <Scrollbar>
                            <TableContainer >
                                <Table>
                                    <OrderListHead
                                        order={order}
                                        orderBy={orderedBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={sellPositions?.length || 0}
                                        numSelected={selected?.length || 0}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {sellPositions
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const { id, symbol, positionInitialMargin, notional, totalPositionRatio, leverage, sidePositionRatio } = row;
                                                const isItemSelected = selected.indexOf(symbol) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                    >
                                                        <TableCell align="center">
                                                            <Typography variant="subtitle2" noWrap>
                                                                {symbol}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">{fCurrency(-notional)}</TableCell>
                                                        <TableCell align="center">{`${sidePositionRatio.toFixed(2)}%`}</TableCell>
                                                        <TableCell align="center">{`${totalPositionRatio.toFixed(2)}%`}</TableCell>
                                                        <TableCell align="center">{fCurrency(positionInitialMargin)}</TableCell>
                                                        <TableCell align="center">{leverage}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[8]}
                            component="div"
                            count={sellPositions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Card>
    );
};

export default Positions;
