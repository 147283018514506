import { filter, orderBy, flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Box,
    Tab,
    Tabs,
    Button,
    CircularProgress,
    Fade,
    Snackbar,
    Alert,
    ButtonGroup,
    TextField
} from '@mui/material';
// components
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs'
import {
    CustomerTotal
} from '../components/_dashboard/app';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { OrderListHead, OrderListToolbar } from '../components/_dashboard/order';
import { getCustomerDetail, getCustomers, getCustomerTotal } from '../services/accountServices'
//

import { fCDateTime } from '../utils/formatTime';
import { fCurrency, fPercent } from '../utils/formatNumber';
import './Overview.css';

// import { tradesAtom } from '../recoil/atoms';

const TABLE_HEAD = [
    { id: 'name', label: '实盘名' },
    { id: 'remark', label: '备注' },
    { id: 'totalBaseAmount', label: '初始资金' },
    { id: 'totalEquity', label: '当前总净值' },
    { id: 'totalRateOfReturn', label: '总收益率' },
    { id: 'spotEquity', label: '现货账户净值' },
    { id: 'swapEquity', label: '合约账户净值' },
    { id: 'coinRateOfReturn', label: '币本位收益率' },
    { id: 'spotRateOfReturn', label: '现货账户收益率' },
    { id: 'swapRateOfReturn', label: '合约账户收益率' },
    { id: 'coinEquity ', label: '币本位净值' },
    { id: 'source', label: '交易所' },
    { id: 'startTime', label: '开始时间' },
];

const SWAP_POSITION_TABLE_HEAD = [
    { id: 'symbol', label: '交易对' },
    { id: 'notional', label: '持仓大小' },
    { id: 'sidePositionRatio', label: '（多/空）持仓比例' },
    { id: 'totalPositionRatio', label: '总持仓比例' },
    { id: 'positionInitialMargin', label: '保证金' },
    { id: 'leverage', label: '杠杆' },
];

// ---------------------------------------------------------------------


const Overview = () => {

    const account = localStorage.getItem('account')
    const theme = useTheme();
    const red = theme.palette.error.main
    const green = theme.palette.success.main
    const [detail, setDetail] = useState([]);
    const [total, setTotal] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(7, 'day').$d);
    const [endDate, setEndDate] = useState(new Date());
    const setDateRange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        getCustomers().then(({ data }) => {
            setCustomers(data)
            setCustomerId(data[0].id)
        })

    }, [])

    useEffect(() => {
        setDetail([])
        setTotal([])
        if (customerId) {
            getCustomerDetail(customerId).then(({ data }) => {
                if (data?.id === customerId) {
                    setDetail(data?.accountInfoDetailVOS)
                    endDate?.setHours(23, 59, 59)
                    startDate?.setHours(0, 0, 0)
                    const startTime = startDate?.getTime()
                    const endTime = endDate?.getTime()
                    if (endDate && startDate) {
                        getCustomerTotal({ customerId, startTime, endTime }).then(({ data: total }) => {
                            setTotal(total)
                        })
                    }
                }
            })
        }
    }, [customerId])

    useEffect(() => {
        if (customerId) {
            endDate?.setHours(23, 59, 59)
            startDate?.setHours(0, 0, 0)
            const startTime = startDate?.getTime()
            const endTime = endDate?.getTime()
            if (endDate && startDate) {
                getCustomerTotal({ customerId, startTime, endTime }).then(({ data: total }) => {
                    setTotal(total)
                })
            }
        }
    }, [startDate, endDate])

    const handleChange = (event, newValue) => {
        setCustomerId(newValue);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <Page title="客户概览页">
            <Container style={{ maxWidth: '1600px' }}>
                <Box sx={{ mb: 3 }}>
                    <Tabs value={customerId} onChange={handleChange} scrollButtons variant="scrollable">
                        {
                            customers.map((item) => (<Tab style={{ fontSize: '20px' }} label={item.name} key={item.id} value={item.id} />))
                        }
                    </Tabs>
                </Box>
                <Card sx={{ p: 3 }}>
                    <div style={{
                        position: 'absolute', top: '35px', right: '24px'
                    }}>
                        <DatePicker
                            selectsRange
                            startDate={startDate}
                            dateFormat="yyyy/MM/dd"
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            className="data-picker"
                        />
                    </div>
                    <CustomerTotal customerTotal={total} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h5" gutterBottom>
                            实盘清单
                        </Typography>
                    </Stack>

                    <Card>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <OrderListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={detail.length}
                                    />
                                    <TableBody>
                                        {detail.map((row) => {
                                            const { source, id, name, startTime, totalBaseAmount, totalEquity, totalRateOfReturn, coinRateOfReturn, coinEquity, spotEquity, spotRateOfReturn, swapEquity, swapRateOfReturn, remark } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                >
                                                    <TableCell
                                                        align="center"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {
                                                            source === 'BINANCE' &&
                                                            <RouterLink
                                                                to="/dashboard/app/"
                                                                state={{ accountId: id, accountName: name }}
                                                                style={{ color: `${theme.palette.primary.link}` }}
                                                            >
                                                                {name}
                                                            </RouterLink>
                                                        }
                                                        {
                                                            source === 'OKX' &&
                                                            <Typography
                                                                onClick={() => { setSnackbarOpen(true) }}
                                                                style={{ color: `${theme.palette.primary.link}` }}
                                                            >
                                                                {name}
                                                            </Typography>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" style={{ minWidth: '100px' }}>{remark}</TableCell>

                                                    <TableCell align="center">{fCurrency(totalBaseAmount)}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(totalEquity)}</TableCell>
                                                    <TableCell align="center" style={{ color: `${totalRateOfReturn > 0 ? red : green}`, minWidth: '150px' }}>{totalRateOfReturn === null ? '-' : fPercent(totalRateOfReturn * 100, '0.000%')}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(spotEquity)}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(swapEquity)}</TableCell>
                                                    <TableCell align="center" style={{ color: `${coinRateOfReturn > 0 ? red : green}`, minWidth: '150px' }}>{coinRateOfReturn === null ? '-' : fPercent(coinRateOfReturn * 100, '0.000%')}</TableCell>
                                                    <TableCell align="center" style={{ color: `${spotRateOfReturn > 0 ? red : green}`, minWidth: '150px' }}>{spotRateOfReturn === null ? '-' : fPercent(spotRateOfReturn * 100, '0.000%')}</TableCell>
                                                    <TableCell align="center" style={{ color: `${swapRateOfReturn > 0 ? red : green}`, minWidth: '150px' }}>{swapRateOfReturn === null ? '-' : fPercent(swapRateOfReturn * 100, '0.000%')}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '150px' }}>{fCurrency(coinEquity)}</TableCell>
                                                    <TableCell align="center">{source}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '150px' }}>{startTime}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody >
                                </Table >
                            </TableContainer >
                        </Scrollbar >
                    </Card >
                </Card >
            </Container >
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={3000} onClose={handleSnackbarClose}
            >

                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    暂不支持查看okx交易所详情
                </Alert>
            </Snackbar>
        </Page >
    );
};

export default Overview;
