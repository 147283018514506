import { atom } from 'recoil';

const ownTotalUnrealizedProfit = atom({
  key: 'totalUnrealizedProfit',
  default: {
    totalUnrealizedProfit: 0
  }
});

export default ownTotalUnrealizedProfit;
