import { merge, sum, orderBy, flatten } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';

// material
import { Card, CardHeader, Box, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { fCurrency } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { incomesAtom, updateTimeAtom, tradesAtom } from '../../../recoil/atoms';
import SymbolTradesAnalysis from '../../charts/SymbolTradesAnalysis'

// ----------------------------------------------------------------------
const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;
const ChartWrapperStyle = styled('div')(({ theme }) => ({
  '& .apexcharts-canvas svg': { userSelect: 'text' },
}));

const TopPerformers = () => {
  const theme = useTheme();
  const incomes = useRecoilValue(incomesAtom);
  const updateTime = useRecoilValue(updateTimeAtom);
  const [nDay, setNDay] = useState(30)
  const timestamp = updateTime - nDay * ONE_DAY_TIMESTAMP;
  const pnlRepartition = {};
  const buyRepartition = {};
  const sellRepartition = {};
  const trades = useRecoilValue(tradesAtom);
  const totalTrades = flatten(Object.values(trades))?.filter((trade) => trade.time >= timestamp)
  console.log('@@@totalTrades', totalTrades)

  flatten(Object.values(trades))?.filter((inc) => inc.time >= timestamp).forEach((inc) => {
    pnlRepartition[inc?.symbol] = (pnlRepartition[inc?.symbol] || 0) + JSON.parse(inc?.realizedPnl);
  });

  // 做多币种盈亏
  flatten(Object.values(trades))?.filter((trade) => trade.time >= timestamp && trade.side === 'SELL').forEach((trade) => {
    buyRepartition[trade?.symbol] = (buyRepartition[trade?.symbol] || 0) + JSON.parse(trade?.realizedPnl);
  });
  // 做空币种盈亏
  flatten(Object.values(trades))?.filter((trade) => trade.time >= timestamp && trade.side === 'BUY').forEach((trade) => {
    sellRepartition[trade?.symbol] = (sellRepartition[trade?.symbol] || 0) + JSON.parse(trade?.realizedPnl);
  });

  const orderedPerformers = orderBy(
    Object.keys(pnlRepartition).map((key) => ({ label: key, value: pnlRepartition[key] })),
    'value',
    'desc'
  );
  let sliceOrderedPerformers = []
  if (orderedPerformers.length > 14) {
    sliceOrderedPerformers = orderedPerformers.slice(0, 7).concat(orderedPerformers.slice(-7))
  } else {
    sliceOrderedPerformers = orderedPerformers
  }
  // 做多币种排行
  let sliceOrderedBuyPerformers = []
  const orderedBuyPerformers = orderBy(
    Object.keys(buyRepartition).map((key) => ({ label: key, value: buyRepartition[key] })),
    'value',
    'desc'
  );
  if (orderedBuyPerformers.length > 14) {
    sliceOrderedBuyPerformers = orderedBuyPerformers.slice(0, 7).concat(orderedBuyPerformers.slice(-7))
  } else {
    sliceOrderedBuyPerformers = orderedBuyPerformers
  }

  // 做多币种排行
  let sliceOrderedSellPerformers = []
  const orderedSellPerformers = orderBy(
    Object.keys(sellRepartition).map((key) => ({ label: key, value: sellRepartition[key] })),
    'value',
    'desc'
  );
  if (orderedSellPerformers.length > 14) {
    sliceOrderedSellPerformers = orderedSellPerformers.slice(0, 7).concat(orderedSellPerformers.slice(-7))
  } else {
    sliceOrderedSellPerformers = orderedSellPerformers
  }
  return (
    <ChartWrapperStyle>
      <Card>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-helper-label">Interval</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={nDay}
            label="Age"
            onChange={(event) => {
              setNDay(event.target.value)
            }}
          >
            <MenuItem value={1}>today</MenuItem>
            <MenuItem value={3}>last 3 days</MenuItem>
            <MenuItem value={7}>last 7 days</MenuItem>
            <MenuItem value={30}>last 30 days</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ mx: 1 }} dir="ltr">
          <RouterLink
            to="/dashboard/symbolIncome"
            state={{ orderedPerformers: sliceOrderedPerformers, interval: nDay }}
            style={{ position: 'absolute', top: '24px', right: '24px', color: `${theme.palette.primary.link}` }}>
            more
          </RouterLink>
          <CardHeader
            title="Top Performers"
            subheader={`${fCurrency(sum(orderedPerformers.map((o) => o.value)))} last ${nDay} days`}
          />
          <Content orderedPerformers={sliceOrderedPerformers} nDay={nDay} />
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6} >
              <RouterLink
                to="/dashboard/symbolIncome"
                state={{ orderedPerformers: sliceOrderedBuyPerformers, interval: nDay }}
                style={{ position: 'absolute', top: '65px', right: '24px', color: `${theme.palette.primary.link}` }}>
                more
              </RouterLink>
              <CardHeader
                title="Buy Top Performers"
                subheader={`${fCurrency(sum(orderedBuyPerformers.map((o) => o.value)))} last ${nDay} days`}
              />
              <Content orderedPerformers={sliceOrderedBuyPerformers} nDay={nDay} colors={[theme.palette.chart.blue[0]]} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} >
              <RouterLink
                to="/dashboard/symbolIncome"
                state={{ orderedPerformers: sliceOrderedSellPerformers, interval: nDay }}
                style={{ position: 'absolute', top: '65px', right: '24px', color: `${theme.palette.primary.link}` }}>
                more
              </RouterLink>
              <CardHeader
                title="Sell Top Performers"
                subheader={`${fCurrency(sum(orderedSellPerformers.map((o) => o.value)))} last ${nDay} days`}
              />
              <Content orderedPerformers={sliceOrderedSellPerformers} nDay={nDay} colors={[theme.palette.chart.yellow[0]]} />
            </Grid>
          </Grid>
          <SymbolTradesAnalysis
            interval={nDay}
            symbolTrades={totalTrades || []}
          />
        </Box>
      </Card >
    </ChartWrapperStyle>

  );
};
const Content = ({ orderedPerformers = [], nDay, colors }) => {
  const chartOptions = merge(BaseOptionChart(), {
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fCurrency(seriesName),
        title: {
          formatter: () => `Last ${nDay} days:`
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      categories: orderedPerformers?.map((o) => o.label)
    }
  });

  const CHART_DATA = [{ data: orderedPerformers?.map((o) => o.value) }];

  return (
    <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={500} />
  )
};

export default TopPerformers;
