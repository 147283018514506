import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number, f = '$0,0.000') {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : f);
}

export function fPercent(number, f = '0.0%') {
  const res = numeral(number / 100).format(f);

  const indicator = number > 0 ? '+' : '';

  return indicator + res;
}

export function ratePercent(number, f = '0.0%') {
  const res = numeral(number / 100).format(f);
  return res;
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.000a'), '.000', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fNumber3(number) {
  return numeral(number).format('0.000');
}
export function fNumber4(number) {
  return numeral(number).format('0.0000');
}
export function fNumber2(number) {
  return numeral(number).format('0.00');
}