import data from '@iconify/icons-eva/menu-2-fill';
import { Grid, Container, Box, Typography, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getSymbolIncomes } from '../../../services/incomeServices'
import SymbolLine from '../../charts/SymbolLine';
import { accountIdAtom } from '../../../recoil/atoms';
import BNBprice from '../../../layouts/dashboard/BNBprice';

const Commission = () => {
    const [intervalDay, setInterval] = useState(30);
    const [data, setData] = useState([]);
    const accountId = useRecoilValue(accountIdAtom)
    useEffect(() => {
        getSymbolIncomes({ accountId, asset: 'BNB', incomeType: 'COMMISSION' }).then(({ data }) => {
            setData(data.map(item => ({
                balance: - item.balance,
                time: item.time
            })))
        })
    }, [accountId])
    return (
        <Card>
            <BNBprice />
            <SymbolLine
                incomeData={data}
                title="手续费"
                unit='BNB'
                interval={intervalDay}
                subTitle="手续费单位为BNB"
                onChange={(interval) => setInterval(interval)}
            />
        </Card>
    )
}

export default Commission