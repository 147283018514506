import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { merge, sum, max, flatten, min } from 'lodash';
import { useLocation } from "react-router-dom";
import { accountAtom, incomesAtom, tradesAtom, updateTimeAtom, ownAccountAtom, ownUnrealizedProfitAtom, ownAccountDataAtom, echartLineAtom, accountIdAtom, ownWalletBalance } from '../recoil/atoms';
import { getTradesOfTheWeek, getUserTradesOfTheDay, getTradesOfTheMonth } from '../services/tradesServices';
import { getIncomesOfTheWeek, getUserIncomesOfTheDay, getIncomesOfThMonth } from '../services/incomeServices';
import { getUserNowAccount, getOwnUserAccount, getOwnTotalWalletBalance } from '../services/accountServices';
import Dashboard from './DashboardApp'

const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;

// ----------------------------------------------------------------------

const INTERVAL_FETCH = 600000; // 10 minute

const App = () => {
  const [intervalId, setIntervalId] = useState();

  const setTradesRecoil = useSetRecoilState(tradesAtom);
  const setIncomesRecoil = useSetRecoilState(incomesAtom);
  const setAccountRecoil = useSetRecoilState(accountAtom);
  const setUpdateTimeRecoil = useSetRecoilState(updateTimeAtom);
  const setOwnAccountRecoil = useSetRecoilState(ownAccountAtom);
  const setOwnAccountDataAtom = useSetRecoilState(ownAccountDataAtom);
  const setEchartLineAtom = useSetRecoilState(echartLineAtom);
  const setOwnWalletBalanceAtom = useSetRecoilState(ownWalletBalance);
  const setOwnUnrealizedProfitAtom = useSetRecoilState(ownUnrealizedProfitAtom);
  const { state } = useLocation();
  // const accountId = useRecoilValue(accountIdAtom)

  if (!state?.accountId) {
    window.location.href = '/overview'
  }
  const { accountId } = state

  const getDailyData = () => {
    getUserNowAccount({ accountId }).then((account) => setAccountRecoil(account));
    getUserTradesOfTheDay({ accountId }).then((trades) =>
      setTradesRecoil((oldTrades) => ({
        ...oldTrades,
        [format(new Date(), 'MM/dd/yyyy')]: trades
      }))
    );
    getUserIncomesOfTheDay({ accountId }).then((incomes) =>
      setIncomesRecoil((oldIncomes) => ({
        ...oldIncomes,
        [format(new Date(), 'MM/dd/yyyy')]: incomes
      }))
    );

    setUpdateTimeRecoil(new Date());
    console.log('Updating your daily data... time: ', new Date());
  };

  const periodicallyFetchDailyData = () => {
    const id = setInterval(getDailyData, INTERVAL_FETCH);
    setIntervalId(id);
  };

  useEffect(() => {
    console.log('Fetching your data');
    getOwnUserAccount({ accountId, startTimestamp: new Date().getTime() - 5 * ONE_DAY_TIMESTAMP, sample: 25 }).then(({ data: accountData }) => {
      const temp = accountData.map((item) => item.totalMarginBalance).filter((item) => item > 0)
      const maxData = Math.round(max(temp))
      const minData = Math.round(min(temp))
      setEchartLineAtom({ minData, maxData })
      setOwnUnrealizedProfitAtom({ totalUnrealizedProfit: accountData[accountData.length - 1].totalUnrealizedProfit })
      setOwnAccountDataAtom(accountData.map((item) => ([item.time, item.totalMarginBalance])))
    });

    getOwnTotalWalletBalance({ accountId }).then(({ data: accountData }) => {
      setOwnAccountRecoil({ totalWalletBalance: accountData[accountData.length - 1].balance })
      // setOwnWalletBalanceAtom(accountData.map((item) => ({ time: item.time, totalWalletBalance: item.totalWalletBalance })).filter(item => item.totalWalletBalance !== 0))
      setOwnWalletBalanceAtom(accountData.map((item) => ({ time: item.time, totalWalletBalance: item.balance })))
    });
    getIncomesOfThMonth({ accountId }).then((incomes) => setIncomesRecoil(incomes));
    getUserNowAccount({ accountId }).then((account) => setAccountRecoil(account));
    getTradesOfTheMonth({ accountId }).then((trades) => setTradesRecoil(trades));

    setUpdateTimeRecoil(new Date());

    periodicallyFetchDailyData();
    return () => clearInterval(intervalId);
  }, [accountId]);

  return (
    <Dashboard />
  );
};

export default App;
