import { atom } from 'recoil';

const echartLineAtom= atom({
  key: 'echartLineState',
  default: {
    minData: 5000,
    maxData: 6000,
  }
});

export default echartLineAtom;
