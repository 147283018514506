import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import syncFill from '@iconify/icons-eva/sync-fill';
import peopleFill from '@iconify/icons-eva/people-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const createSidebarConfig = () => {
  const account = localStorage.getItem('account')
  const sidebarConfig = [
    {
      title: '概览',
      path: '/overview',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: '更换账号',
      path: '/loginPage',
      icon: getIcon(syncFill)
    }
  ]
  if (account === 'Jeff' || account === 'admin' || account === 'rioyu' || account === 'cjy') {
    sidebarConfig.splice(1, 0, {
      title: '交易',
      path: '/trade',
      icon: getIcon(briefcaseFill)
    },
      {
        title: '历史收益记录',
        path: '/incomeHistory',
        icon: getIcon(fileTextFill)
      },
      {
        title: '模拟盘',
        path: '/dashboard/simStatistics',
        icon: getIcon(fileTextFill)
      },
      {
        title: '账号管理',
        path: '/admin',
        icon: getIcon(peopleFill)
      }
    )
  } else if (account === 'Dewave01') {
    sidebarConfig.splice(1, 0,
      // {
      //   title: '交易',
      //   path: '/trade',
      //   icon: getIcon(briefcaseFill)
      // },
      {
        title: '历史收益记录',
        path: '/incomeHistory',
        icon: getIcon(fileTextFill)
      },
      {
        title: '模拟盘',
        path: '/dashboard/simStatistics',
        icon: getIcon(fileTextFill)
      },
      {
        title: '账号管理',
        path: '/admin',
        icon: getIcon(peopleFill)
      }
    )
  }
  else if (account === 'nate') {
    sidebarConfig.splice(1, 0, {
      title: '交易',
      path: '/trade',
      icon: getIcon(briefcaseFill)
    },
      {
        title: '模拟盘',
        path: '/dashboard/simStatistics',
        icon: getIcon(fileTextFill)
      }
    )
  }
  return sidebarConfig
}

export default createSidebarConfig;
