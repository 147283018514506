import crypto from 'crypto';

import { CONFIGURATION } from '../config/api-keys';

const URL_PROFIX = process.env.REACT_APP_ENV === 'production' ? '/api' : '/api'

const BINANCE_URLS = {
  spot: {
    trades: 'https://api.binance.com/api/v3/myTrades',
    income: 'https://api.binance.com/api/v3/account',
    account: 'https://api.binance.com/api/v3/account'
  },
  futures: {
    // trades: 'https://fapi.binance.com/fapi/v1/userTrades',
    // income: 'https://fapi.binance.com/fapi/v1/income',
    // account: 'https://fapi.binance.com/fapi/v2/account',
    ownAccount: `${URL_PROFIX}/analyze/swap/totalMarginBalance`,
    nowAccount: `${URL_PROFIX}/analyze/swap/nowAccount`,
    ownBalance: `${URL_PROFIX}/analyze/swap/balance`,
    ownIncome: `${URL_PROFIX}/analyze/swap/income`,
    ownTrades: `${URL_PROFIX}/analyze/swap/trade`,
    currentDetail: `${URL_PROFIX}/analyze/spot/currentDetail`,
    ids: `${URL_PROFIX}/analyze/account/ids`,
    simPerformance: `${URL_PROFIX}/simulation/performance`,
    simStatistics: `${URL_PROFIX}/simulation/statistics`,
    ownTotalWalletBalance: `${URL_PROFIX}/analyze/swap/dailyBalances`,
    customerDetail: `${URL_PROFIX}/customer/detail`,
    customerList: `${URL_PROFIX}/customer/list`,
    customerListAll: `${URL_PROFIX}/customer/listAll`,
    customerTotal: `${URL_PROFIX}/customer/total`,
    login: `${URL_PROFIX}/login`,
    transfer: `${URL_PROFIX}/transfer/makeTransfer`,
    customerAccount: `${URL_PROFIX}/customer/accounts`,
    createAccount: `${URL_PROFIX}/manage/account/create`,
    updateAccount: `${URL_PROFIX}/manage/account/update`,
    resetAccount: `${URL_PROFIX}/manage/account/reset`,
    clearAccount: `${URL_PROFIX}/manage/account/clear`,
    clearCustomer: `${URL_PROFIX}/manage/customer/clear`,
    createCustomer: `${URL_PROFIX}/manage/customer/create`,
    userList: `${URL_PROFIX}/manage/user/list`,
    customerUserRelation: `${URL_PROFIX}/manage/customer/userRelation`,
    customerAssign: `${URL_PROFIX}/manage/customer/assign`,
    orderType: `${URL_PROFIX}/enums/orderType`,
    swapSymbols: `${URL_PROFIX}/enums/swap/symbols`,
    spotSymbols: `${URL_PROFIX}/enums/spot/symbols`,
    coinSymbols: `${URL_PROFIX}/enums/coin/symbols`,
    transferEnum: `${URL_PROFIX}/enums/transferEnum`,
    orderSide: `${URL_PROFIX}/enums/orderSide`,
    openOrders: `${URL_PROFIX}/order/openOrders`,
    makeOrder: `${URL_PROFIX}/order/makeOrder`,
    cancelOrder: `${URL_PROFIX}/order/cancelOrder`,
    calculateOrder: `${URL_PROFIX}/order/calculate`,
    positionRisk: `${URL_PROFIX}/analyze/SWAP/positionRisk`,
    setLeverage: `${URL_PROFIX}/setting/leverage`,
    dualSidePosition: `${URL_PROFIX}/setting/swap/dualSidePosition`,
    coinDualSidePosition: `${URL_PROFIX}/setting/coin/dualSidePosition`,
    accounts: `${URL_PROFIX}/manage/account/show`,
    allOrders: `${URL_PROFIX}/order/allOrders`,
    incomeHistory: `${URL_PROFIX}/customer/snapshots`,
    coinNowAccount: `${URL_PROFIX}/analyze/coin/nowAccount`,
    coinPositionRisk: `${URL_PROFIX}/analyze/query/leverage`
  }
};

export const BINANCE_HEADERS = {
  Accept: 'Application/json',
  'X-MBX-APIKEY': CONFIGURATION.binance.key
};
// startTime=1652892067942&endTime=1655484068177&limit=100&timestamp=1655484069785
export const getSignature = (queryParams) => crypto.createHmac('sha256', CONFIGURATION.binance.secret).update(queryParams).digest('hex')

export const getUrl = (type) => BINANCE_URLS[CONFIGURATION.binance.exchangeType][type];
