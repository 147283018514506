import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '@mui/material/styles';
import { Box, Card } from '@mui/material';
import { merge, sum, max, flatten, min } from 'lodash';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { getOwnUserAccount } from '../../../services/accountServices'
import { accountIdAtom, ownAccountAtom, ownAccountDataAtom, echartLineAtom } from '../../../recoil/atoms';

const INTERVAL_FETCH = 5 * 1000 * 60;
const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000;


const EchartsLine = () => {
  const chartRef = useRef()
  let myChart = null
  const setOwnAccountDataAtom = useSetRecoilState(ownAccountDataAtom);
  const setEchartLineAtom = useSetRecoilState(echartLineAtom)
  const accountId = useRecoilValue(accountIdAtom)


  const data = useRecoilValue(ownAccountDataAtom)
  console.log('@@@owndata', data);
  const { maxData, minData } = useRecoilValue(echartLineAtom)
  const [startTimestamp, setStartTimestamp] = useState(new Date().getTime())
  const [intervalId, setIntervalId] = useState();
  const [dataZoom, setDataZoom] = useState([
    {
      type: 'inside',
      start: 90,
      end: 100
    },
    {
      start: 90,
      end: 100
    }
  ]);

  const theme = useTheme();

  const options = {
    tooltip: {
      trigger: 'axis',
      position(pt) {
        return [pt[0], '10%'];
      }
    },
    title: {
      left: 'center',
      text: '净值曲线',
      textStyle: {
        color: theme.palette.text.primary
      }
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisLabel: {
        show: true,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      axisLabel: {
        show: true,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
      min: minData,
      max: maxData,
    },
    dataZoom,
    backgroundColor: theme.palette.background.default,
    fontColor: theme.palette.background.default,
    series: [
      {
        name: '净值',
        type: 'line',
        smooth: true,
        symbol: 'none',
        areaStyle: {},
        data
      }
    ]
  };

  function renderChart() {
    const chart = echarts.getInstanceByDom(chartRef.current)
    if (chart) {
      myChart = chart
    } else {
      myChart = echarts.init(chartRef.current)
    }
    myChart.setOption(options)
  }

  const getAccountData = () => getOwnUserAccount({ accountId, startTimestamp }).then(({ data: accountData }) => {
    if (accountData && accountData.length > 0) {
      // setOwnAccountRecoil(accountData[accountData.length - 1])
      setStartTimestamp(accountData[accountData.length - 1]?.time + 1)
      setOwnAccountDataAtom([...data, ...accountData.map((item) => ([item?.time, item?.totalMarginBalance]))])
      const temp = data.map((item) => item[1]).filter((item) => item > 0) || []
      setEchartLineAtom({ maxData: Math.round(max(temp)), minData: Math.round(min(temp)) })
    }
  })

  const getData = () => {
    const newDataZoom = myChart?.getOption()?.dataZoom;
    if (newDataZoom) {
      setDataZoom(newDataZoom)
    }
    getAccountData()
  }

  useInterval(getData, INTERVAL_FETCH)

  useEffect(() => {
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    return () => {
      clearInterval(intervalId);
      return myChart && myChart.dispose()
    }
  }, [])

  useEffect(() => {
    options.series.data = data;
    options.yAxis.min = parseInt(minData * 0.99, 10);
    options.yAxis.max = parseInt(maxData * 1.01, 10);
    options.dataZoom = dataZoom;
    renderChart()
  }, [data, minData, maxData, theme])

  return (
    <Card>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <div style={{ height: "500px" }} ref={chartRef} />
      </Box>
    </Card>
  )
}

function useInterval(cb, delay) {
  const ref = useRef()

  useEffect(() => {
    ref.current = cb
  })

  useEffect(() => {
    const timer = setInterval(() => ref.current(), delay)
    return () => clearInterval(timer)
  }, [delay])
}

export default EchartsLine