import { merge, sum, max, flatten, min } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';

// material
import { Card, Stack, CardHeader, Typography, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { BaseOptionChart } from '../../charts';
import { ownWalletBalance } from '../../../recoil/atoms';
import { fCDateTime } from '../../../utils/formatTime';

const CustomerTotal = ({ customerTotal }) => {
    const total = customerTotal.map(item => item.equity)
    const time = customerTotal.map(item => item.time)
    const theme = useTheme();
    // const [nDay, setNDay] = useState(30);

    const chartOptions = merge(BaseOptionChart(), {
        colors: [theme.palette.chart.yellow[0]],
        stroke: { width: [2] },
        plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
        fill: { type: ['solid', 'gradient', 'solid'] },
        labels: time,
        xaxis: {
            type: 'datetime',
            forceNiceScale: true,
            labels: {
                datetimeUTC: false
            }
        },
        yaxis: [
            {
                seriesName: 'Balance',
                // min: min(total) > 0 ? Math.ceil(min(total) * 0.95) : Math.ceil(min(total) * 1.05),
                // max: max(total) > 0 ? Math.ceil(max(total) * 1.05) : Math.ceil(max(total) * 0.95),
                forceNiceScale: true,
                title: 'Balance',
                decimalsInFloat: 0
            }
        ],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `$${y.toFixed(1)}`;
                    }
                    return y;
                }
            },
            x: {
                show: true,
                shared: true,
                formatter: (x) => {
                    if (typeof x !== 'undefined') {
                        return `${fCDateTime(x)}`;
                    }
                    return x;
                }
            }
        }
    });

    const CHART_DATA = [
        {
            name: 'Balance',
            type: 'area',
            data: total
        }
    ];

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 200 }} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h5" gutterBottom>
                    总净值曲线
                </Typography>
            </Stack>
            <Box dir="ltr">
                <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={400} />
            </Box>
        </div >
    );
};

export default CustomerTotal;
