
import { flatten } from 'lodash';
import { useRecoilValue } from 'recoil';

// material
import { Grid, Container, Card, CardHeader } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { accountIdAtom, incomesAtom, accountAtom, tradesAtom } from '../recoil/atoms';
// import { getSymbolIncomes } from '../services/incomeServices'
import { getSimPerformance, getSimStatistics } from '../services/simServices'
// components
import Page from '../components/Page';
import SymbolLine from '../components/charts/SymbolLine';

const SimPerformance = () => {
    const [simData, setSimData] = useState([])
    useEffect(() => {
        getSimPerformance().then(({ data }) => {
            setSimData(data)
        })
    }, [])

    const renderItem = (policy) => policy?.subPolicyList?.map((subPolicy) => {
        const policyData = subPolicy?.lines?.map((item) => ({ balance: item.balance, time: item.time }))
        return (
            <Grid item xs={12} md={6} lg={6} key={subPolicy?.subPolicy || ''}>
                <Card>
                    <SymbolLine
                        incomeData={policyData}
                        symbol={subPolicy?.subPolicy || ''}
                        showInterval={false}
                    />
                </Card>
            </Grid>
        )
    })


    return (
        <Page title="策略净值页">
            {
                simData.map((policy) => (
                    <Container style={{ marginTop: 20 }} maxWidth="xl" key={policy.policy}>
                        <Card sx={{ p: 2 }}>
                            <CardHeader
                                title={`${policy?.policy}的收益曲线`}
                                subheader={`总共${policy?.subPolicyList.length - 1}个子策略`}
                                sx={{ py: 2 }}
                            />
                            <Grid container spacing={3} >
                                {
                                    renderItem(policy)
                                }
                            </Grid>
                        </Card>
                    </Container>
                ))
            }
        </Page >
    );
}

export default SimPerformance