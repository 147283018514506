// material
import { Grid, Container, Box, Typography } from '@mui/material';

// components
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from "react-router-dom";
import Page from '../components/Page';
import {
  // AppTasks,
  // AppNewsUpdate,
  LastOrders,
  PositionsRepartition,
  PerformanceOverview,
  WalletBalance,
  // AppTrafficBySite ,
  // AppCurrentSubject,
  EchartsLine,
  TopPerformers,
  Commission,
  Positions
} from '../components/_dashboard/app';

import Summary from '../layouts/dashboard/Summary';
import { accountNameAtom } from '../recoil/atoms';

// ----------------------------------------------------------------------

const DashboardApp = () => {
  // const accountName = useRecoilValue(accountNameAtom)
  const { state: { accountName } } = useLocation();
  return (
    <Page title="量化平台">
      <Container style={{ marginTop: 20 }} maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{`实盘${accountName}`}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Summary />

          <Grid item xs={12} md={12} lg={12}>
            <EchartsLine />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
          <PerformanceOverview />
        </Grid> */}

          <Grid item xs={12} md={12} lg={12}>
            <WalletBalance />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Positions />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TopPerformers />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={6}>
          <PositionsRepartition />
        </Grid> */}


          <Grid item xs={12} md={6} lg={6}>
            <Commission />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <LastOrders />
          </Grid>


          {/* <Grid item xs={12} md={6} lg={8}>
          <AppNewsUpdate news={news} />
        </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
          <AppTrafficBySite />
        </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
          <AppTasks />
        </Grid> */}
          {/* <Grid item xs={12} md={6} lg={4}>
          <AppCurrentSubject />
        </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
};

export default DashboardApp;
