
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Grid,
    Container,
    Card,
    CardHeader,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Stack,
    Modal,
    Box,
} from '@mui/material';
//
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { getSymbolIncomes } from '../services/incomeServices'
import { getSimPerformance, getSimStatistics } from '../services/simServices'
// components
import Page from '../components/Page';
import SymbolLine from '../components/charts/SymbolLine';
import MultiLine from '../components/charts/MultiLine';
import { OrderListHead } from '../components/_dashboard/order';
import { fCurrency, fShortenNumber, ratePercent } from '../utils/formatNumber';
import { formatDuring } from '../utils/formatTime';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: '70%', xs: '100%' },
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: { xs: 0, lg: 3 },
};

const TABLE_HEAD = [
    { id: 'name', label: '子策略名' },
    { id: 'baseAmount', label: '初始资金' },
    { id: 'currentEquity', label: '当前净值' },
    { id: 'floatEquityIncreaseRate', label: '浮动净值增长率' },
    { id: 'winRate', label: '胜率' },
    { id: 'equityIncreaseRate', label: '净值增长率' },
    { id: 'currentWithdrawAmount', label: '当前回撤值' },
    { id: 'maxWithdrawAmount', label: '最大回撤值' },
    { id: 'maxWithdrawRate', label: '最大回撤率' },
    { id: 'maxWithdrawTime', label: '最大回撤时间' },
    { id: 'meanWithdrawTime', label: '平均回撤时间' },
    { id: 'profitLossRate', label: '盈亏比' },
    { id: 'duration', label: '模拟持续时间' },
    { id: 'emptyCount', label: '空仓周期数' },
];

const SimStatistics = () => {
    const theme = useTheme();
    const red = theme.palette.error.main
    const green = theme.palette.success.main
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [performanceData, setPerformanceData] = useState([])
    const [statisticsData, setStatisticsData] = useState([])
    const [policyData, setPolicyData] = useState([])
    const [policyName, setPolicyName] = useState('')
    useEffect(() => {
        Promise.all([getSimPerformance(), getSimStatistics()]).then(([simData, StatisticsData]) => {
            const performanceData = {}
            simData?.data?.forEach((policy) => {
                const subPerformance = {}
                policy?.subPolicyList?.forEach((sub) => {
                    subPerformance[sub?.subPolicy] = sub?.lines
                })
                performanceData[policy?.policy] = subPerformance
            })
            setPerformanceData(performanceData)
            setStatisticsData(StatisticsData?.data)
        })
    }, [])
    const handleOnClick = ({ policy, subPolicy }) => {
        if (subPolicy) {
            const policyData = [{
                list: performanceData[policy][subPolicy],
                name: subPolicy
            }]
            setPolicyData(policyData)
            setPolicyName(subPolicy)
        } else {
            const policyDatas = Object.keys(performanceData[policy]).map((key) => ({
                list: performanceData[policy][key],
                name: key
            }))
            setPolicyData(policyDatas)
            setPolicyName(policy)
        }
        setOpen(true)
    }

    const renderItem = (policy) => (
        <Card sx={{ p: 1, m: 1 }} style={{ width: "100%" }}>
            <TableContainer >
                <Table>
                    <OrderListHead
                        headLabel={TABLE_HEAD}
                        rowCount={policy.subPolicyList?.length}
                    />
                    <TableBody>
                        {policy?.subPolicyList?.map((row) => {
                            const { baseAmount, currentEquity, floatEquityIncreaseRate, winRate, equityIncreaseRate, currentWithdrawAmount, maxWithdrawAmount, maxWithdrawRate, maxWithdrawTime, meanWithdrawTime, profitLossRate, duration, emptyCount } = row.statistic;

                            return (
                                <TableRow
                                    hover
                                    key={row.subPolicy}
                                    tabIndex={-1}
                                    role="checkbox"
                                >
                                    <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => handleOnClick({ policy: policy.policy, subPolicy: row.subPolicy })}>
                                        <Typography variant="subtitle2" noWrap style={{ color: `${theme.palette.primary.link}`, textDecoration: 'underline' }}>
                                            {row.subPolicy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">{fCurrency(baseAmount)}</TableCell>
                                    <TableCell align="center">{fCurrency(currentEquity)}</TableCell>
                                    {/* <TableCell align="center">{fCurrency(floatEquityIncreaseRate)}</TableCell> */}
                                    <TableCell align="center" style={{ color: `${floatEquityIncreaseRate > 0 ? red : green}` }}>{ratePercent(floatEquityIncreaseRate * 100, '0.000%')}</TableCell>
                                    <TableCell align="center" style={{ color: `${winRate > 0.5 ? red : green}` }}>{ratePercent(winRate * 100, '0.000%')}</TableCell>
                                    <TableCell align="center" style={{ color: `${equityIncreaseRate > 0 ? red : green}` }}>{equityIncreaseRate === null ? '-' : ratePercent(equityIncreaseRate * 100, '0.000%')}</TableCell>
                                    <TableCell align="center">{fCurrency(currentWithdrawAmount)}</TableCell>
                                    <TableCell align="center">{fCurrency(maxWithdrawAmount)}</TableCell>
                                    <TableCell align="center">{ratePercent(maxWithdrawRate * 100, '0.000%')}</TableCell>
                                    <TableCell align="center">{formatDuring(maxWithdrawTime)}</TableCell>
                                    <TableCell align="center">{formatDuring(meanWithdrawTime)}</TableCell>
                                    <TableCell align="center" style={{ color: `${profitLossRate > 1 ? red : green}` }}>{fShortenNumber(profitLossRate)}</TableCell>
                                    <TableCell align="center">{formatDuring(duration)}</TableCell>
                                    <TableCell align="center">{emptyCount}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card >
    )


    return (
        <Page title="模拟盘统计">
            {
                statisticsData.map((policy) => (
                    <Container style={{ marginTop: 20, position: 'relative' }} maxWidth="xl" key={policy.policy} >
                        {/* <RouterLink
                            to="/dashboard/simPerformance"
                            style={{ position: 'absolute', top: '24px', right: '55px', zIndex: 1, color: `${theme.palette.primary.link}` }}>
                            策略收益曲线
                        </RouterLink> */}
                        <Card sx={{ p: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} onClick={() => handleOnClick({ policy: policy.policy })} style={{ cursor: 'pointer' }}>
                                <Typography variant="h5" gutterBottom style={{ color: `${theme.palette.primary.link}`, textDecoration: 'underline' }}>
                                    {`策略名:${policy?.policy}`}
                                </Typography>
                            </Stack>
                            <Grid container >
                                {
                                    renderItem(policy)
                                }
                            </Grid>
                        </Card>
                    </Container>
                ))
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style} >

                    <MultiLine
                        incomeDatas={policyData}
                        title={`策略${policyName}的收益曲线`}
                    />
                </Card>
            </Modal>
        </Page >
    );
}

export default SimStatistics